import React, { ChangeEventHandler } from 'react';
import { qosOptions } from './CostsSettings.config.react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import { strings } from '~utils/strings';
import { Placement } from 'react-bootstrap/esm/Overlay';

import './QoSGroup.react.scss';
import { qos } from '../Costs.types.react';

type QoSGroupProps = {
  classNamePrefix?: string;
  overlayPlacement?: Placement;
  value?: qos | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

function QoSGroup({
  classNamePrefix = 'right-sizing',
  overlayPlacement = 'left',
  value,
  onChange = () => {},
}: QoSGroupProps) {
  const toDisplay = Object.entries(qosOptions).map(([key, label]) => (
    <div key={`${classNamePrefix}__${key}`}>
      <div className="form-check form-check-inline">
        <input
          type="radio"
          id={key}
          key={key}
          value={key}
          name="customRadioInline"
          className="form-check-input"
          onChange={onChange}
          checked={value == key}
        />
        <label className="form-check-label" htmlFor={key}>
          {label}
        </label>
      </div>
      <OverlayTrigger
        placement={overlayPlacement}
        delay={{ show: 0, hide: 1000 }}
        overlay={
          <Popover id={`tooltip-${key}`}>
            <Popover.Content>
              {strings.efficiency[key]}{' '}
              {key == 'burstable_plus' && (
                <a
                  href="https://insights.docs.fairwinds.com/first-steps/cost-efficiency/#quality-of-service-qos-recommendations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See more in docs
                </a>
              )}
            </Popover.Content>
          </Popover>
        }
      >
        <InfoIcon style={{ marginBottom: '0.75rem', marginRight: '1rem' }} />
      </OverlayTrigger>
    </div>
  ));

  return (
    <Form.Group className="radio-group">
      <div className={`${classNamePrefix}__qos-options`}>{toDisplay}</div>
    </Form.Group>
  );
}

export default QoSGroup;
