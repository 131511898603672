import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { strings } from '~utils/strings';

import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import { formatDatetime } from '~utils/global.helpers.react';
import { DateType, OptionType } from '~utils/global.types.react';
import dayjs, { Dayjs } from 'dayjs';

import Select from 'react-select';
import { LoadingSpinner } from '@fairwindsops/ui-components';

const severitiesOptions = [
  { label: 'Critical', value: 'CRITICAL' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
];

const statusOptions: OptionType[] = [
  { label: 'Introduced', value: 'introduced' },
  { label: 'Fixed', value: 'fixed' },
  { label: 'Resolved', value: 'resolved' },
];

export type CvesReportModalProps = {
  visible: boolean;
  onClose: () => void;
  onExportCves: (selectedTimeRange: DateType, selectedStatus: string[], selectedSeverity: string[]) => void;
  initialState?: {
    dateRange?: DateType | null;
    severities?: string[];
    status?: string[];
  };
};

const CvesReportModal = ({ visible, onClose, onExportCves, initialState }: CvesReportModalProps) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<DateType>(() => {
    if (initialState?.dateRange) {
      return {
        start: formatDatetime(dayjs(initialState?.dateRange.start)),
        end: formatDatetime(dayjs(initialState?.dateRange.end)),
      };
    }
    return {
      start: formatDatetime(dayjs().subtract(1, 'month')),
      end: formatDatetime(dayjs()),
    };
  });

  const [selectedStatus, setSelectedStatus] = useState<OptionType[]>(
    () =>
      (initialState?.status
        ?.map((status) => statusOptions.find((option) => option.value.toLowerCase() === status.toLowerCase()))
        .filter((status) => status !== undefined) as OptionType[]) || [],
  );
  const [selectedSeverity, setSelectedSeverity] = useState<OptionType[]>(
    () =>
      (initialState?.severities
        ?.map((severity) => severitiesOptions.find((option) => option.value.toLowerCase() === severity.toLowerCase()))
        .filter((severity) => severity !== undefined) as OptionType[]) || [],
  );
  const [processing, setProcessing] = useState<boolean>(false);

  const handleSubmit = async () => {
    setProcessing(true);
    await onExportCves(
      selectedTimeRange,
      selectedStatus?.map((status) => status.value) || [],
      selectedSeverity?.map((severity) => severity.value) || [],
    );
    setProcessing(false);
  };

  const filterByDate = (start?: Dayjs | Date, end?: Date | Dayjs) => {
    if (!start || !end) {
      return;
    }

    const changedDates = {
      start: formatDatetime(dayjs(start)),
      end: formatDatetime(dayjs(end)),
    };
    setSelectedTimeRange(changedDates);
  };

  return (
    <Modal show={visible} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{strings.general.exportCSV}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="action-items-reports__filter-container">
          <Datepicker
            isClearable={true}
            onDateSelect={filterByDate}
            startDate={selectedTimeRange.start}
            endDate={selectedTimeRange.end}
            minDate={13}
            className="action-items-reports"
          />
          <Select
            isMulti
            options={statusOptions}
            value={selectedStatus}
            placeholder="Filter by status"
            isSearchable
            isClearable
            backspaceRemovesValue
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={setSelectedStatus}
          />
          <Select
            isMulti
            options={severitiesOptions}
            value={selectedSeverity}
            placeholder="Filter by severity"
            isSearchable
            isClearable
            backspaceRemovesValue
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={setSelectedSeverity}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="create-list-modal__actions">
          <Button variant="secondary" onClick={onClose}>
            {strings.general.Cancel}
          </Button>
          <div>
            <Button
              disabled={processing}
              onClick={() => {
                handleSubmit();
              }}
              data-cy="submit-export_cve-button"
            >
              {strings.general.Export}
            </Button>
          </div>
        </div>
      </Modal.Footer>
      <div className="loading-spinner-container">{processing && <LoadingSpinner />}</div>
    </Modal>
  );
};

export default CvesReportModal;
