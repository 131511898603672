import React from 'react';
import { Column, TableInstance } from 'react-table';
import { StylesConfig } from 'react-select';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import RepoSeverities from '../../../../../../repositories/components/RepoSeverities/RepoSeverities.react';

import { Cluster } from '~globalTypes';
import { VulnerabilitiesActionItems, Labels } from './VulnerabilitiesItemsTable.types.react';
import { RESIZED_WIDTH_STORAGE_KEYS, getCurrentTimezone } from '~reactHelpers';

import { strings } from '~utils/strings';
import TicketCell from '~views/repositories/components/ActionItemsTable/TicketCell.react';

export const PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

dayjs.extend(timezone);

export const SELECT_STYLES: StylesConfig<unknown, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

export const TABLE_COLUMNS = (
  ColumnFiltering: (tableInstance: TableInstance) => JSX.Element,
  ColumnDateRangeFiltering: (tableInstance: TableInstance) => JSX.Element,
  showCreateTicketModal: (actionItem: VulnerabilitiesActionItems) => void,
  cluster?: Cluster,
): Column<VulnerabilitiesActionItems>[] => {
  const resizedWidth = JSON.parse(
    localStorage.getItem(RESIZED_WIDTH_STORAGE_KEYS.vulnerabilitiesAllImagesPage) || '{}',
  );

  const columns = [
    {
      Header: strings.vulnerabilities.title,
      accessor: 'title',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => <span title={instance.row.original.title}>{instance.row.original.title}</span>,
      width: resizedWidth['title'] || 350,
      minWidth: 150,
      maxWidth: 500,
    },
    {
      Header: strings.vulnerabilities.severity,
      accessor: 'severityLabel',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: (instance: TableInstance) => (
        <RepoSeverities
          id={instance.row.original.id}
          repoSeverity={instance.row.original.severityLabel}
          nonePluralsLabel={Labels.Vulnerability}
          pluralsLabel={Labels.Vulnerabilities}
        />
      ),
      width: resizedWidth['severityLabel'] || 250,
      minWidth: 130,
      maxWidth: 250,
    },
    {
      Header: strings.vulnerabilities.vulnerabilities,
      accessor: 'vulnerabilitiesCount',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.vulnerabilitiesCount}>{instance.row.original.vulnerabilitiesCount}</span>
      ),
      width: resizedWidth['vulnerabilitiesCount'] || 150,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      Header: strings.vulnerabilities.clustersHeader,
      accessor: 'cluster',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.cluster.join(',')}>{instance.row.original.cluster.join(',')}</span>
      ),
      width: resizedWidth['cluster'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: strings.vulnerabilities.repositories,
      accessor: 'repositoriesCount',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.repositoriesCount}>{instance.row.original.repositoriesCount}</span>
      ),
      width: resizedWidth['repositoriesCount'] || 250,
      minWidth: 130,
      maxWidth: 250,
    },
    {
      Header: strings.vulnerabilities.workloads,
      accessor: 'workloadsCount',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.workloadsCount}>{instance.row.original.workloadsCount}</span>
      ),
      width: resizedWidth['workloadsCount'] || 150,
      minWidth: 130,
      maxWidth: 250,
    },
    {
      Header: strings.vulnerabilities.packages,
      accessor: 'package',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.package.join(',')}>{instance.row.original.package.join(',')}</span>
      ),
      width: resizedWidth['package'] || 250,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      Header: strings.vulnerabilities.recommendedTag,
      accessor: 'hasRecommendedTag',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => {
        const recommended = instance.row.original.recommended
          ? instance.row.original.recommended
          : Labels.RecommendationsUnavailable;
        return <span title={recommended}>{recommended}</span>;
      },
      width: resizedWidth['hasRecommendedTag'] || 150,
      minWidth: 130,
      maxWidth: 300,
    },
    {
      Header: strings.vulnerabilities.riskReduction,
      accessor: 'riskReduction',
      className: 'no-overflow',
      Cell: (instance: TableInstance) => {
        const riskReduction = instance.row.original.riskReduction
          ? instance.row.original.riskReduction.toFixed(2) + '%'
          : Labels.RiskReductionUnavailable;
        return <span title={riskReduction}>{riskReduction}</span>;
      },
      width: resizedWidth['riskReduction'] || 130,
      minWidth: 130,
      maxWidth: 300,
    },
    {
      Header: strings.vulnerabilities.ticket,
      accessor: 'ticketCreated',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => {
        return (
          <TicketCell
            ticketLink={instance.row.original.ticketLink}
            onCreateTicketClick={() => showCreateTicketModal(instance.row.original)}
          />
        );
      },
      width: resizedWidth['ticketCreated'] || 140,
      minWidth: 140,
      maxWidth: 200,
    },
    {
      Header: strings.vulnerabilities.lastScanned,
      accessor: 'lastScanned',
      className: 'no-overflow',
      Filter: ColumnDateRangeFiltering,
      Cell: (instance: TableInstance) => {
        const timeZone = getCurrentTimezone();

        let lastScanned = strings.general.unavailable;

        if (instance.row.original.lastScanned) {
          lastScanned = timeZone
            ? dayjs(instance.row.original.lastScanned).tz(timeZone).format(`MM/DD/YYYY [${strings.general.at}] HH:mm`)
            : dayjs(instance.row.original.lastScanned).format(`MM/DD/YYYY [${strings.general.at}] HH:mm`);
        }

        return <span title={lastScanned}>{lastScanned}</span>;
      },
      width: resizedWidth['lastScanned'] || 230,
      minWidth: 230,
      maxWidth: 300,
    },
  ] as unknown as Column<VulnerabilitiesActionItems>[];
  return cluster ? columns.filter((column) => column.Header !== 'Clusters') : columns;
};

export const COLUMNS_ORDER = [
  strings.general.selection,
  'Title',
  'Severity',
  'Vulnerabilities',
  'Clusters',
  'Repositories',
  'Workloads',
  'Recommended',
  'Risk Reduction',
];
