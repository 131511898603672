import React from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableInstance } from 'react-table';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import ExportButton from '~reactComponents/ExportButton/ExportButton.react';

import ToggleColumnsVisibility from '../ToggleColumnsVisibility/ToggleColumnsVisibility.react';

import { ImageDetailItem } from '../ImageDetailItemsTable/ImageDetailItemsTable.types.react';

import { PAGE_SIZE_OPTIONS } from '../ImageDetailItemsTable/ImageDetailItemsTable.config.react';

import { EXPORT_VULNERABILITIES_IMAGE_DETAILS_OPTIONS } from '../../../ReactVulnerabilities.helpers.react';

import './TableCardHeader.react.scss';

dayjs.extend(LocalizedFormat);

type TableCardHeader = {
  globalSearch: string | null;
  isHidingColsSupported?: boolean;
  selectedImageDetailItem?: ImageDetailItem | null;
  tableInstance: TableInstance<ImageDetailItem>;
  totalRowCount: number;
  handleExport: (action: string) => Promise<void>;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
  setGlobalSearch: (keywords: string | null) => any;
};

const TableCardHeader = ({
  globalSearch,
  isHidingColsSupported,
  selectedImageDetailItem,
  tableInstance,
  totalRowCount,
  handleExport,
  setLoaded,
  setGlobalSearch,
}: TableCardHeader) => {
  const {
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <div className="vulnerabilities-action--items--header">
      <Row>
        <Col className="vulnerabilities-action--items--header--section vulnerabilities-action--items--header--section--top image-detail-header-section">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="vulnerabilities-action--items--header--section__search"
              value={globalSearch || ''}
              onChange={(e) => setGlobalSearch(e.target.value.trim() || null)}
            />
          </Form>
          <div className="image-detail-header-section--export">
            <ExportButton
              exportActionItemsOptions={EXPORT_VULNERABILITIES_IMAGE_DETAILS_OPTIONS}
              size="sm"
              title="Export"
              handleExport={handleExport}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="vulnerabilities-action--items--header--section">
          <span className="vulnerabilities-action--items--header--section__showing">
            {`Showing ${Math.max(pageSize * pageIndex + 1)} -
              ${
                pageSize * pageIndex + pageSize > totalRowCount ? totalRowCount : pageSize * pageIndex + pageSize
              } of ${totalRowCount} results`}
          </span>
          {isHidingColsSupported && (
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <ToggleColumnsVisibility tableInstance={tableInstance} />
            </DndProvider>
          )}
        </Col>
        <Col className="vulnerabilities-action--items--header--section end">
          <span className="vulnerabilities-action--items--header--section__results">Results per page</span>
          <DropdownButton
            title={pageSize}
            size="sm"
            className="vulnerabilities-action--items--header--section__dropdown vulnerabilities-action--items--header--section__dropdown__page-size"
          >
            {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  if (pageSize === pageSizeOption) return;
                  setLoaded && setLoaded(false);
                  setPageSize(pageSizeOption);
                }}
              >
                {pageSizeOption}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
    </div>
  );
};

export default TableCardHeader;
