import React from 'react';
import { TableInstance } from 'react-table';
import { Item, ItemParams, Menu, Submenu } from 'react-contexify';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { CONTEXT_MENU_ID } from '../../ActionItems.config.react';
import { handleResolutionSelection, handleAssigneeSelection } from '../../ActionItems.helpers.react';

import { ActionItem, Member } from '../../ActionItems.types.react';
import { IRouter, IRoute } from '~globalTypes';

import ExportIcon from '~assetIcons/export.svg';
import ResolveIcon from '~assetIcons/resolve.svg';
import SimilarIcon from '~assetIcons/similar.svg';
import AssignIcon from '~assetIcons/assign.svg';
import SnoozeIcon from '~reactComponents/Icons/Snooze.icon.react';

import { ACTION_ITEMS } from '~reactComponents/NavigationReact/Navigation.config.react';
import logger from '~logger';
import { ACTION_ITEM_RESOLUTIONS, SNOOZE_OPTIONS } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';

dayjs.extend(timezone);

type ContextMenuProps = {
  members: Member[];
  baseURL: string;
  toast: any;
  router: () => IRouter;
  route: IRoute;
  resolveItem?: React.Dispatch<React.SetStateAction<number>>;
  setShowModal?: React.Dispatch<React.SetStateAction<any>>;
  handleOpenTicketModal: (actionItem: ActionItem) => void;
  fetchActionItemsDebounced?: () => void;
  tableInstance?: TableInstance<ActionItem>;
  isEditable: boolean;
};

const ContextMenu: React.FC<any> = ({
  members,
  baseURL,
  toast,
  router,
  handleOpenTicketModal,
  resolveItem,
  route,
  setShowModal,
  fetchActionItemsDebounced,
  tableInstance,
  isEditable,
}: ContextMenuProps) => {
  const timeZone = getCurrentTimezone();

  const handleCreateTicketClick = ({ props: { actionItem } }: ItemParams) => {
    setShowModal && setShowModal(false);
    handleOpenTicketModal(actionItem);
  };

  const handleSimilarItemClick = ({ props: { actionItem } }: ItemParams) => {
    if (route.name === ACTION_ITEMS) {
      tableInstance?.setFilter('Title', [
        {
          value: actionItem.Title,
          label: actionItem.Title,
        },
      ]);
      fetchActionItemsDebounced && fetchActionItemsDebounced();
    } else {
      const splitURL = baseURL.split('/');
      const org = splitURL[splitURL.length - 1];
      router().push({
        path: `/orgs/${org}/action-items`,
        query: { Title: actionItem.Title },
      });
    }
  };

  const handleResolution = async (items: ItemParams, resolution: { value: string; label: string }) => {
    try {
      await handleResolutionSelection(resolution.value, [items.props.actionItem], baseURL);
    } catch (e) {
      logger.logError('error_updating_resolution', e);
      toast.error(e.message);
      return;
    }
    toast.success(strings.actionItemsPage.contextMenu.resolutionSuccess);
    resolveItem && resolveItem(items.props.actionItem.ID);
    setShowModal && setShowModal(false);
  };

  const handleAssignee = async (email: string, items: ItemParams) => {
    try {
      await handleAssigneeSelection(email, [items.props.actionItem], baseURL);
    } catch (e) {
      logger.logError('error_updating_assignee', e);
      toast.error(e.message);
      return;
    }

    if (email) {
      toast.success(strings.actionItemsPage.contextMenu.actionItemAssigned.replace('$email', email));
    } else {
      toast.success(strings.actionItemsPage.contextMenu.actionItemUnassigned);
    }
    fetchActionItemsDebounced && fetchActionItemsDebounced();
  };

  const handleSnoozeClick = async ({ props: { actionItem } }: ItemParams, date: string) => {
    try {
      if (date !== 'unsnooze') {
        actionItem.SnoozeUntil = timeZone ? dayjs().add(1, date).tz(timeZone).format() : dayjs().add(1, date).format();
        await sendRequest('PATCH', `${baseURL}/action-items/snooze`, { data: actionItem }, null);
        actionItem.Resolution = strings.snoozedResolution;
      } else {
        actionItem.Resolution = '';
        await sendRequest('PATCH', `${baseURL}/action-items/resolution`, { data: actionItem }, null);
      }
      toast.success(
        strings.actionItemsPage.contextMenu.snoozeSuccess.replace(
          strings.actionItemsPage.contextMenu.action,
          date === 'unsnooze'
            ? strings.actionItemsPage.contextMenu.unsnoozed
            : strings.actionItemsPage.contextMenu.snoozedFor.replace(
                strings.actionItemsPage.contextMenu.period,
                strings.general[date],
              ),
        ),
      );
    } catch (e) {
      logger.logError('error_snoozing_action_item', e);
      toast.error(e.message);
    }

    fetchActionItemsDebounced && fetchActionItemsDebounced();
  };

  return (
    <Menu id={CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Item className="context--menu__title context--menu__no-hover" disabled>
        <span>{strings.actionItemsPage.contextMenu.additionalActions}</span>
      </Item>
      <Item hidden={!isEditable} onClick={handleCreateTicketClick}>
        <img
          className="ticket-icon"
          src={ExportIcon}
          style={{ paddingRight: '1rem' }}
          alt={strings.actionItemsPage.contextMenu.ticketIcon}
        />
        {strings.general.createTicket}
      </Item>
      <Submenu
        hidden={!isEditable}
        label={
          <>
            <img
              className="resolve-icon"
              src={ResolveIcon}
              style={{ paddingRight: '1rem' }}
              alt={strings.actionItemsPage.contextMenu.resolveIcon}
            />
            {strings.general.Resolve}
          </>
        }
      >
        {ACTION_ITEM_RESOLUTIONS.map((resolution, idx) => (
          <Item closeOnClick={false} onClick={(items) => handleResolution(items, resolution)} key={idx}>
            {resolution.label}
          </Item>
        ))}
      </Submenu>
      <Submenu
        hidden={!isEditable}
        label={
          <>
            <SnoozeIcon />
            {strings.general.Snooze}
          </>
        }
      >
        {SNOOZE_OPTIONS.map((item) => {
          return (
            <Item
              hidden={(item) => item?.props?.actionItem.Resolution === strings.snoozedResolution}
              onClick={(actionItem) => handleSnoozeClick(actionItem, item)}
              key={'month'}
            >
              1 {item}
            </Item>
          );
        })}
        <Item
          closeOnClick={false}
          hidden={(item) => item?.props?.actionItem.Resolution !== strings.snoozedResolution}
          onClick={(actionItem) => handleSnoozeClick(actionItem, 'unsnooze')}
          key={'unsnooze'}
        >
          {strings.general.Unsnooze}
        </Item>
      </Submenu>
      <Submenu
        hidden={!isEditable}
        label={
          <>
            <img
              className="assign-icon"
              src={AssignIcon}
              style={{ paddingRight: '1rem' }}
              alt={strings.actionItemsPage.contextMenu.assignIcon}
            />
            {strings.general.Assign}
          </>
        }
      >
        <Item
          onClick={(items) => {
            handleAssignee('', items);
          }}
        >
          {strings.general.Unassign}
        </Item>
        {members?.map((member, idx) => (
          <Item
            onClick={(items) => {
              handleAssignee(member.Email, items);
            }}
            key={idx}
          >
            {member.Email}
          </Item>
        ))}
      </Submenu>
      <Item onClick={handleSimilarItemClick}>
        <img
          className="similar-icon"
          src={SimilarIcon}
          style={{ paddingRight: '1rem' }}
          alt={strings.actionItemsPage.contextMenu.similarIcon}
        />{' '}
        {strings.general.SeeSimilarItems}
      </Item>
    </Menu>
  );
};

export default ContextMenu;
