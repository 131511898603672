import React from 'react';
import { TableInstance } from 'react-table';
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { AuditLog } from './AuditLogs.types.react';

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

dayjs.extend(LocalizedFormat);

type TableCardHeaderProps = {
  selected: AuditLog | null;
  totalRowCount: number;
  tableInstance: TableInstance<AuditLog>;
};

const TableCardHeader = ({ selected, totalRowCount, tableInstance }: TableCardHeaderProps) => {
  const {
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <div className="action--items--header">
      <Row>
        <Col className="action--items--header--section">
          <span className="action--items--header--section__showing">
            Showing {Math.max(pageSize * pageIndex, 1)}-{pageSize * pageIndex + pageSize} of {totalRowCount} results
          </span>
        </Col>
        <Col className={`action--items--header--section end ${selected ? 'd-none' : ''}`}>
          <span className="action--items--header--section__results">Results per page</span>
          <DropdownButton
            title={pageSize}
            size="sm"
            className="action--items--header--section__dropdown action--items--header--section__dropdown__page-size"
          >
            {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  setPageSize(pageSizeOption);
                }}
              >
                {pageSizeOption}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
    </div>
  );
};

export default TableCardHeader;
