import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { toast } from 'react-hot-toast';

import { DemoOrgName } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { convertJsonToCsv, downloadCsv } from '~utils/helpers';
import { ActionItem, ActionItemFilters, List } from './ActionItems.types.react';
import { EXPORT_FIELDS_MAP, EXPORT_FORMAT } from './ActionItems.config.react';
import logger from '~logger';
import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

interface SOC2Export {
  'Section ID': string;
  'Control Criteria': string;
  'Date Last Checked': string;
}

type formattedSOC2Export = SOC2Export | Record<string, unknown>;

export const exportSOC2Report = async (
  baseURL: string,
  organization: Record<string, unknown>,
  SOC2: unknown,
): Promise<unknown> => {
  if ((!SOC2 || organization.TrialExpiresAt) && organization.Name !== DemoOrgName) {
    // TODO: Need to create modal
    // displayUpgradePrompt('soc2');
    return;
  }
  const timeZone = getCurrentTimezone();
  const URL = `${baseURL}/soc2-report`;
  const soc2Report = await sendRequest('GET', URL, {}, null);
  const now = timeZone ? dayjs().tz(timeZone).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');

  const formattedSOC2Report: formattedSOC2Export[] = [];
  Object.keys(soc2Report).forEach((key) => {
    const soc2Section: formattedSOC2Export = {};
    soc2Section['Section ID'] = soc2Report[key].SectionID;
    soc2Section['Control Criteria'] = soc2Report[key].ControlCriteria;
    if (!soc2Report[key]?.Evidence) {
      soc2Section.Evidence = '';
    } else {
      soc2Section.Evidence = soc2Report[key].Evidence.join('\n\n');
    }
    soc2Section['Date Last Checked'] = now;
    formattedSOC2Report.push(soc2Section);
  });

  const csvData = await convertJsonToCsv(formattedSOC2Report);
  downloadCsv(csvData, `SOC 2 Report_${organization.Name}`);
};

export const exportNSAReport = async (baseURL: string, cluster: string) => {
  const report = await sendRequest('GET', `${baseURL}/clusters/${cluster}/nsa-report?format=csv`, {}, null);
  downloadCsv(report, `NSA_Report_${cluster}`);
};

export const fetchActionItems = async (
  searchParams: URLSearchParams,
  baseUrl: string,
): Promise<{ data: ActionItem[]; headers: { [key: string]: unknown } }> => {
  try {
    const { data, headers } = await sendRequest(
      'GET',
      `${baseUrl}/action-items?${searchParams.toString()}`,
      { returnHeaders: true },
      null,
    );
    return { data, headers };
  } catch (e) {
    toast.error('Unable to fetch Action Items. Try again.');
    logger.logError('error_fetching_action_items_export', e);
    return { data: [], headers: {} };
  }
};

export const exportActionItems = async (
  searchParams: URLSearchParams,
  baseUrl: string,
  orgName: string,
  clusterName: string,
  listName?: string,
): Promise<void> => {
  const actionItems = await fetchActionItems(searchParams, baseUrl);
  // Return right away if a null response is returned.
  if (!actionItems?.data) {
    throw new Error();
  }
  await createCSVFile(actionItems.data, orgName, clusterName, listName);
};

export const createCSVFile = async (data: ActionItem[], org: string, cluster: string, listName?: string) => {
  const formattedActionItems = formatAIsForExport(data);
  const csvData = await convertJsonToCsv(formattedActionItems, Object.values(EXPORT_FIELDS_MAP));
  const filename = actionItemFileName(org, cluster, listName);
  downloadCsv(csvData, filename);
};

export const formatAIsForExport = (actionItems: ActionItem[]) =>
  actionItems.map((actionItem) => {
    const formattedAI: Record<string, unknown> = {};

    for (const field in EXPORT_FIELDS_MAP) {
      const formattedField = EXPORT_FIELDS_MAP[field];

      if (EXPORT_FORMAT[field as keyof typeof EXPORT_FORMAT]) {
        formattedAI[formattedField] = EXPORT_FORMAT[field](actionItem);
      } else {
        formattedAI[formattedField] = actionItem[field as keyof ActionItem];
      }
    }
    formattedAI.Notes = actionItem.Notes;
    return formattedAI;
  });

export const actionItemFileName = (orgName: string, clusterName?: string, listName?: string): string => {
  let csvFileName = `action-items_${orgName}`;
  if (clusterName) {
    csvFileName += `_${clusterName}`;
  }
  if (listName) {
    csvFileName += `_${listName}`;
  }
  return csvFileName;
};

export const formatResolutionString = (resolution?: string): string => {
  switch (resolution) {
    case '':
      return 'None';
    case strings.workingAsAttendedResolution:
      return 'Working As Intended';
    case strings.willNotFixResolution:
      return "Won't Fix";
    case strings.snoozedResolution:
      return 'Snoozed';
    default:
      return 'None';
  }
};

export const formatTime = (time: string): string => {
  const timeZone = getCurrentTimezone();
  return dayjs(time).isValid()
    ? timeZone
      ? dayjs(time).tz(timeZone).format(strings.dateFormats.LLL)
      : dayjs(time).format(strings.dateFormats.LLL)
    : time;
};

export const formatResourceLabels = (resourceLabels: Record<string, string>) => {
  if (!resourceLabels) return null;
  const formattedLabels = [];
  for (const [key, value] of Object.entries(resourceLabels)) {
    formattedLabels.push(`${key}=${value}`);
  }
  return formattedLabels.join('\n');
};

export const handleAssigneeSelection = async (email: string, selectedRows: ActionItem[], baseUrl: string) => {
  try {
    const assigneeEmail = email.length ? email : null;
    const payload = {
      IDs: selectedRows.map((row) => row.ID),
      AssigneeEmail: assigneeEmail,
    };
    await sendRequest('PATCH', `${baseUrl}/action-items/assignee/bulk`, { data: payload }, null);
  } catch (e) {
    console.error('Error updating assignee', e);
    logger.logError('error_updating_assignee', e);
    throw e;
  }
};

export const handleResolutionSelection = async (Resolution: string, selectedRows: ActionItem[], baseUrl: string) => {
  try {
    const payload = {
      IDs: selectedRows.map((row) => row.ID),
      Resolution,
    };
    await sendRequest('PATCH', `${baseUrl}/action-items/resolution/bulk`, { data: payload }, null);
  } catch (e) {
    console.error('Error updating resolution', e);
    logger.logError('error_updating_resolution', e);
    throw new Error(e);
  }
};

export const handleSnoozeSelection = async (snoozeOption: any, selectedRows: ActionItem[], baseUrl: string) => {
  const timeZone = getCurrentTimezone();
  try {
    const SnoozeUntil = timeZone
      ? dayjs().add(1, snoozeOption).tz(timeZone).format()
      : dayjs().add(1, snoozeOption).format();
    const payload = {
      IDs: selectedRows.map((row) => row.ID),
      SnoozeUntil,
    };
    await sendRequest('PATCH', `${baseUrl}/action-items/snooze/bulk`, { data: payload }, null);
  } catch (e) {
    console.error('Error updating snooze', e);
    logger.logError('error_updating_snooze', e);
    throw new Error(e);
  }
};

export const findMatchingListById = (id: number, lists: List[]): List | undefined => {
  return lists.find((list) => list.ID === id);
};

export const findMatchingListByName = (name: string, lists: List[]): List | undefined => {
  if (name !== '' || name !== undefined) {
    return lists.find((list) => list?.Name.toLowerCase() === name.toLowerCase());
  }
};

export const formattingQuery = (filters: ActionItemFilters): string => {
  const filtersToArray = Object.entries(filters);
  let stringifiedQuery = '';

  filtersToArray
    .filter((filter) => (filter[1] && filter[1][0] !== '') || (filter[1] && filter[1][0] === undefined))
    .forEach((filter): void => {
      let allQueries;
      if (!filter[1]) {
        allQueries = '';
      } else if (typeof filter[1] === 'string') {
        allQueries = filter[1];
      } else {
        allQueries = filter[1].join(', ');
      }
      stringifiedQuery = stringifiedQuery + `${filter[0]}=${allQueries} AND `;
    });
  return stringifiedQuery.slice(0, stringifiedQuery.length - 4);
};
