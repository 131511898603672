// eslint-disable-next-line
import { strings } from '@/utils/strings';
import { COLORS } from '~utils/styling';
import ciSha from '../../ci-sha.txt';
import ciVersion from '../../ci-version.txt';
import ciPluginImageVersion from '../../ci-plugin-image-version.txt';

export {
  DefaultServerHost,
  ReportTypeLabels,
  AdminDomain,
  AdminDomainEmailSuffix,
  NoLimit,
  MemberLimit,
  AdminOnlyAnnotations,
  DangerThreshold,
  CriticalThreshold,
  HighThreshold,
  MediumThreshold,
  LowThreshold,
  NoneThreshold,
  MainBranches,
  PersonalEmailProviders,
  DemoOrgName,
  AgreementVersion,
  PremiumReports,
  ReportTypesForHealthScore,
  RuleContexts,
  ReportTypes,
} from '../../values.json';

export const CI_SHA = ciSha.trim();
export const CI_VERSION = ciVersion.trim();
export const CI_PLUGIN_IMAGE_VERSION = ciPluginImageVersion.trim();

export function getAllImages() {
  return ((ctx) => {
    const keys = ctx.keys();
    const values = keys.map(ctx);
    /* eslint-disable no-param-reassign */
    return keys.reduce((o, k, i) => {
      o[k.split('.')[1].substring(1)] = values[i];
      return o;
    }, {});
    /* eslint-enable no-param-reassign */
  })(require.context('../../public/static/img/reports', false, /.*/));
}

export const NAValue = strings.general.na;
export const NotSet = strings.general.notSet;

export const SNOOZE_OPTIONS = ['Day', 'Week', 'Month'];

export const REPORTS_DETAILS = {
  polaris: {
    name: 'Polaris',
    id: 'polaris',
    description: strings.reportDetails.polaris,
  },
  trivy: {
    name: 'Trivy',
    id: 'trivy',
    description: strings.reportDetails.trivy,
  },
  opa: {
    name: 'Open Policy Agent',
    id: 'opa',
    description: strings.reportDetails.opa,
  },
  'kube-bench': {
    name: 'Kube-bench',
    id: 'kube-bench',
    description: strings.reportDetails.kubeBench,
  },
  nova: {
    name: 'Nova',
    id: 'nova',
    description: strings.reportDetails.nova,
  },
  pluto: {
    name: 'Pluto',
    id: 'pluto',
    description: strings.reportDetails.pluto,
  },
  'rbac-reporter': {
    name: 'RBAC Reporter',
    id: 'rbac-reporter',
    description: strings.reportDetails.rbacReporter,
  },
  'kube-hunter': {
    name: 'Kube-hunter',
    id: 'kube-hunter',
    description: strings.reportDetails.kubeHunter,
  },
  'prometheus-metrics': {
    name: 'Prometheus Collector',
    id: 'prometheus-metrics',
    description: strings.reportDetails.prometheusMetrics,
    hidden: false,
  },
  workloads: {
    name: 'Workloads',
    id: 'workloads',
    description: strings.reportDetails.workloads,
    required: true,
  },
  admission: {
    name: 'Admission Controller',
    id: strings.admission,
    description: strings.reportDetails.admission,
  },
  'right-sizer': {
    name: 'Right Sizer',
    id: 'right-sizer',
    description: strings.reportDetails.rightSizer,
  },
  falco: {
    name: 'Falco',
    id: 'falco',
    description: strings.reportDetails.falco,
    link: 'https://insights.docs.fairwinds.com/technical-details/reports/falco/#rules',
  },
  cloudcosts: {
    name: 'Cloud Costs',
    id: 'cloudcosts',
    description: strings.reportDetails.cloudcosts,
    link: 'https://insights.docs.fairwinds.com/technical-details/reports/cloud-costs/#aws-billing-integration-configuration',
  },
  kyverno: {
    name: 'Kyverno',
    id: 'kyverno',
    description: strings.reportDetails.kyverno,
    link: 'https://insights.docs.fairwinds.com/technical-details/reports/kyverno/',
  },
};

export const INTEGRATIONS_DETAILS = {
  slack: {
    name: 'Slack',
    id: 'slack',
    description: strings.integrationDetails.slackDesc,
  },
  datadog: {
    name: 'Datadog',
    id: 'datadog',
    description: strings.integrationDetails.datadogDesc,
  },
  github: {
    name: 'Github',
    id: 'github',
    description: strings.integrationDetails.githubDesc,
  },
  jira: {
    name: 'Jira',
    id: 'jira',
    description: strings.integrationDetails.jiraDesc,
  },
  pagerduty: {
    name: 'PagerDuty',
    id: 'pagerduty',
    description: strings.integrationDetails.pagerdutyDesc,
  },
  azure: {
    name: 'Azure DevOps',
    id: 'azure',
    description: strings.integrationDetails.azureDesc,
  },
  msteams: {
    name: 'Microsoft Teams',
    id: 'msteams',
    description: strings.integrationDetails.msteamsDesc,
  },
};

export const JOB_TITLES = {
  title: { value: '', text: strings.jobTitles.jobTitle },
  devops: { value: 'devops', text: strings.jobTitles.devOps },
  engineeringLeader: { value: 'engineeringLeader', text: strings.jobTitles.engLeader },
  architect: { value: 'architect', text: strings.jobTitles.Architect },
  security: { value: 'security', text: strings.jobTitles.Security },
  ceo: { value: 'ceo/founder', text: strings.jobTitles.Ceo },
  product: { value: 'productDevelopment', text: strings.jobTitles.productDev },
};

export const AVATAR_COLORS = ['avatar-orange', 'avatar-pink', 'avatar-purple', 'avatar-blue'];

export const NO_NAMESPACE = 'No namespace';

export const ACTION_ITEM_RESOLUTIONS = [
  {
    label: strings.general.None,
    value: '',
  },
  {
    label: strings.workingAsAttended,
    value: strings.workingAsAttendedResolution,
  },
  {
    label: strings.wontFix,
    value: strings.willNotFixResolution,
  },
];

export const ADMISSION_ACTION_ITEM_RESOLUTIONS = [
  {
    label: strings.general.None,
    value: '',
  },
  {
    label: strings.workingAsAttended,
    value: strings.workingAsAttendedResolution,
  },
  {
    label: strings.wontFix,
    value: strings.willNotFixResolution,
  },
  {
    label: strings.snoozed,
    value: strings.snoozedResolution,
  },
];

export const QOS_RECOMMENDATIONS = [
  strings.qosRecs.Critical,
  strings.qosRecs.Guaranteed,
  strings.qosRecs.Burstable,
  strings.qosRecs.Limited,
];

export const CLUSTERS_COLUMNS = [
  {
    key: 'favorite',
    class: 'favorite-col',
    sortable: true,
  },
  {
    key: 'Cluster',
    sortable: true,
    class: 'cluster-col',
    thStyle: {
      color: COLORS.CORE.PRIMARY,
    },
  },
  {
    key: 'health_score',
    sortable: true,
    class: 'score-col',
    thStyle: {
      color: COLORS.CORE.PRIMARY,
    },
  },
  {
    key: 'action_items',
    sortable: true,
    class: 'action-items-col',
    thStyle: {
      color: COLORS.CORE.PRIMARY,
    },
  },
  {
    key: 'trends',
    sortable: false,
    class: 'trends-col',
    thStyle: {
      color: COLORS.CORE.PRIMARY,
      padding: '12px',
      paddingLeft: '0',
    },
  },
];

export const stepRoutes = {
  agentInstall: 'clusterhub-install',
  configureReport: 'clusterreport-hub',
  resolveFirstActionItem: 'action-items',
  setupAlerts: strings.noTranslate.settings,
};

export const orgStepRoutes = {
  agentInstalled: 'clusters',
  repositoryConnected: 'repositories',
  teamSetup: strings.noTranslate.settings,
  alertsSetup: 'notifications',
};

export const setupProcessTitles = {
  agentInstall: strings.general.installAgent,
  configureReport: strings.constants.configureReport,
  resolveFirstActionItem: strings.constants.resolveFirstActionItem,
  setupAlerts: strings.constants.setupAlertPreferences,
};

export const orgSetupProcessTitles = {
  agentInstalled: strings.general.installAgent,
  repositoryConnected: strings.constants.connectRepo,
  teamSetup: strings.constants.setupTeam,
  alertsSetup: strings.constants.setupAlertPreferences,
};

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

export const SHOW_DARK_FEATURES_ENDPOINTS = ['localhost', 'k8s.insights.fairwinds.com'];

export const DEPRECATED_REPORTS = ['resource-metrics'];

export const IS_PRODUCTION = window.location.host === 'insights.fairwinds.com';

export const IS_PLG_UPGRADE_MODAL_SHOW = 'isPLGUpgradeModalShown';

export const redirectPaths = {
  'action-items': 'action-items-cluster',
  'react-vulnerabilities-all-images': 'react-vulnerabilities-all-images-cluster',
  clusters: 'clusteroverview',
  'org-dashboard': 'clusteroverview',
  'node-efficiency': 'node-efficiency',
  'org-efficiency': 'node-efficiency',
};
