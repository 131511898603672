import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

import { Card } from '@fairwindsops/ui-components';
import AudioIcon from '../../../reactComponents/Icons/Audio.icon.react';

import { ValidationType, LoginProps, ResponseType } from '../auth.types.react';

import {
  ORGS,
  LOGIN_SSO,
  RECOVER_PASSWORD,
  CHECK_CONFIRMATION,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { getCaptchaImage, playCaptchaAudio } from '../auth.helpers.react';
import { loginValidationSchema } from '../auth.config.react';

import { strings } from '~utils/strings';
import { vexillaShould } from '~utils/feature-flags';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { Title } from '~utils/texts.react';

import './style.scss';

const Login = ({ router, route, store }: LoginProps) => {
  const redir = route?.query?.redir || '/orgs';
  const [requiresCaptcha, setRequiresCaptcha] = useState<boolean>(false);
  const [captchaId, setCaptchaId] = useState<string>('');
  const [error, setError] = useState<string>('');

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ValidationType>({
    defaultValues: { captcha: '' },
    resolver: joiResolver(loginValidationSchema),
    mode: strings.noTranslate.onSubmit,
  });

  useEffect(() => {
    const currentUser = store().getters.user;

    if (currentUser && currentUser.FirstName && currentUser.LastName && currentUser.Confirmed) {
      if (!route?.query?.forceLogin) {
        router().push({ name: ORGS });
      }
    } else if (currentUser && currentUser.FirstName && currentUser.LastName) {
      router().push({ name: CHECK_CONFIRMATION });
    } else {
      setValue('email', route?.query?.email || '');
    }
  }, []);

  const valid = useMemo(() => {
    const captchaValid = !requiresCaptcha || (requiresCaptcha && watch(strings.noTranslate.captcha).length === 6);
    return watch('email') && watch('password') && captchaValid;
  }, [watch('email'), watch('password'), watch(strings.noTranslate.captcha)]);

  const responseFailureActions = (input: Record<string, string>, response: ResponseType) => {
    logger.logError(strings.noTranslate.authLogin, {
      email: input.email,
      success: false,
      error: response.error,
    });
    setError(response.error);
    setCaptchaId(response.captchaId);
    setValue(strings.noTranslate.captcha, '');
    setRequiresCaptcha(response.requiresCaptcha);
  };

  const handleOnSubmit = handleSubmit(async (input) => {
    let response;
    const data = {
      email: input.email,
      password: input.password,
      captchaId,
      captchaValue: input?.captcha || '',
    };

    try {
      response = await sendRequest('POST', '/v0/auth/login', { data, showSuccessAlert: false }, null);

      logger.logEvent(strings.noTranslate.authLogin, {
        email: input.email,
        success: true,
        error: null,
      });

      if (response && response.error) {
        responseFailureActions(input, response);
      } else {
        if (vexillaShould(strings.featureFlags.fixLogin)) {
          router().push({
            path: redir && redir.startsWith('/auth/login') ? '/orgs' : redir,
          });
        } else {
          router().push({ path: redir });
        }
      }
    } catch (e) {
      if (response && response.error) {
        responseFailureActions(input, response);
      } else {
        logger.logError(strings.noTranslate.authLogin, {
          email: input.email,
          success: false,
          error: 'Error while signing in',
        });
        setError(strings.auth.errorSigningIn);
      }
    }
  });

  return (
    <div className="login container">
      {error && (
        <Alert variant="danger" className="login__card">
          {error}
        </Alert>
      )}
      <Card className="login__card">
        <Card.Body padded>
          <div>
            <div className="login__header">
              <h1 className={Title({ size: strings.textStyling.xs })}>{strings.auth.login}</h1>
              <a href="https://www.fairwinds.com/insights-getstarted" data-cy="contact-us-button">
                {strings.contactUs.title}
              </a>
            </div>
            <p>{strings.auth.mainLoginPageDesc}</p>
          </div>
          <Form onSubmit={handleOnSubmit} noValidate>
            <Form.Group>
              <Form.Label htmlFor="login-email">{strings.Email}</Form.Label>
              <Form.Control
                id="login-email"
                type="email"
                {...register('email')}
                data-cy="email-text-input"
                autoComplete={strings.noTranslate.on}
                title={strings.Email}
              />
              <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="login-password">{strings.navigation.Password}</Form.Label>
              <Form.Control
                type="password"
                {...register('password')}
                data-cy="password-text-input"
                autoComplete={strings.noTranslate.on}
                title={strings.navigation.Password}
                id="login-password"
              />
              <Form.Control.Feedback type="invalid">{errors?.password?.message}</Form.Control.Feedback>
            </Form.Group>
            {requiresCaptcha && (
              <Form.Group>
                <Form.Label htmlFor="login-captcha">{strings.contactUs.Captcha}</Form.Label>
                <div className="login__captcha-image">
                  <img src={getCaptchaImage(captchaId)} alt={captchaId || ''} />
                  <div className="login__audio-container" onClick={() => playCaptchaAudio(captchaId)}>
                    <AudioIcon />
                  </div>
                </div>
                <Form.Control
                  type="text"
                  {...register(strings.noTranslate.captcha, {
                    minLength: 6,
                    maxLength: 6,
                  })}
                  data-cy="captcha-text-input"
                  autoComplete={strings.noTranslate.on}
                  title={strings.noTranslate.captcha}
                  id="login-captcha"
                />
                <Form.Control.Feedback type="invalid">{errors?.captcha?.message}</Form.Control.Feedback>
              </Form.Group>
            )}
            <Button type="submit" variant="primary" disabled={!valid} data-cy="submit-login-button">
              {strings.auth.signIn}
            </Button>
          </Form>
          <div className="login__links-group">
            <a href={router().resolve({ name: RECOVER_PASSWORD }).href} data-cy="forgot-password-button">
              {strings.auth.forgotPassword}
            </a>
            <a href={router().resolve({ name: LOGIN_SSO }).href} data-cy="login-sso-button">
              {strings.navigation.loginSSO}
            </a>
            <a href="https://fairwinds.com/insights" target="_blank">
              {strings.auth.learnMore}
            </a>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
