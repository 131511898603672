// eslint-disable-next-line
import AuditLogs from './AuditLogs.react.tsx';

export default {
  components: {
    'audit-logs': AuditLogs,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
