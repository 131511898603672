import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CardDeck } from 'react-bootstrap';
import {
  Column,
  FilterTypes,
  Row,
  TableInstance,
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import dayjs, { ManipulateType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import toast, { Toaster } from 'react-hot-toast';
import { TriggerEvent, useContextMenu } from 'react-contexify';

import ContextMenu from '../ContextMenu/ContextMenu.react';
import DescriptionPanel from '../DescriptionPanel/DescriptionPanel.react';
import ImprovedRepositories from '../Charts/ImprovedRepositories/ImprovedRepositories.react';
import RepoChanges from '../RepoChanges/RepoChanges.react';
import RepoCommits from '../RepoCommits/RepoCommits.react';
import SelectColumnFilter from '../SelectColumnFilter/SelectColumnFilter.react';
import SeveritiesBreakdown from '../Charts/SeveritiesBreakdown/SeveritiesBreakdown.react';
import TableCardHeader from '../TableCardHeader/TableCardHeader.react';
import TopIssues from '../Charts/TopIssues/TopIssues.react';

import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';
import { Card, ConfirmationDialog } from '@fairwindsops/ui-components';
import EmptyData from '~reactComponents/EmptyData/EmptyData.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import Table from '~reactComponents/Table/Table.react';
import { REACT_REPOSITORIES, REACT_REPOSITORY } from '~reactComponents/NavigationReact/Navigation.config.react';

import {
  CodeScan,
  CommonLabels,
  IndeterminateCheckboxProps,
  IRoute,
  IRouter,
  OptionType,
  Organization,
  RepoActionItemResponse,
  RepoActivity,
  Repository,
} from '~globalTypes';
import { convertFiltersToURL, getCurrentTimezone, PAGES, REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { Labels, RepositoryActionItem } from './ActionItemsTable.types.react';
import {
  COLUMNS_ORDER,
  EXPORT_ACTION_ITEMS_OPTIONS,
  PAGE_SIZE,
  POLLING_SCAN_STATUS_INTERVAL_TIME,
  POLLING_SCAN_STATUS_TIMEOUT,
  SCAN_STATUS_MAP,
  STOP_POLLING_SCAN_STATUSES,
  TABLE_COLUMNS,
} from './ActionItemsTable.config.react';

import './ActionItemsTable.react.scss';
import { exportActionItems, getRepoActionItem, transformActionItems } from './ActionItemsTable.helpers.react';
import { CodeScanActionItem } from '~views/repositories/ReactRepository/ReactRepository.types.react';

import { strings } from '~utils/strings';

dayjs.extend(relativeTime);

type ActionItemsTableProps = {
  activeActionItem?: RepoActionItemResponse;
  baseURL: string;
  cluster: string;
  codeScanActionItems?: CodeScanActionItem[];
  isForcePollingScanStatus?: boolean;
  isMainBranch?: boolean;
  onPullRequestCreated?: (actionItem: RepositoryActionItem) => void;
  organization: Organization;
  repositories?: Repository[];
  repository?: Repository;
  route: IRoute;
  router: () => IRouter;
  savedSelectionsKey?: string;
  selectedBranch?: OptionType;
  selectedCommit?: CodeScan | undefined;
  setActiveActionItem?: React.Dispatch<SetStateAction<RepoActionItemResponse | undefined>>;
  setIsForcePollingScanStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  setJobLogs?: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedCommit?: React.Dispatch<React.SetStateAction<CodeScan | undefined>>;
  setUpdatedScanStatus?: React.Dispatch<React.SetStateAction<{ title: string; cssClass: string } | null>>;
  topBranches?: OptionType[];
  showViewMode?: boolean;
  unlinkTicket: (actionItem: RepositoryActionItem) => void;
  isOwner: boolean;
};

const ActionItemsTable = ({
  activeActionItem,
  baseURL,
  cluster,
  codeScanActionItems,
  isForcePollingScanStatus,
  isMainBranch,
  onPullRequestCreated,
  organization,
  repositories,
  repository,
  route,
  router,
  savedSelectionsKey,
  selectedBranch,
  selectedCommit,
  setActiveActionItem,
  setIsForcePollingScanStatus,
  setJobLogs,
  setSelectedCommit,
  setUpdatedScanStatus,
  topBranches,
  showViewMode,
  unlinkTicket,
  isOwner,
}: ActionItemsTableProps): JSX.Element => {
  const [actionItems, setActionItems] = useState<RepositoryActionItem[]>([]);
  const [selectedActionItem, setSelectedActionItem] = useState<RepositoryActionItem | null>(null);
  const actionItemFilters = useRef<any>();
  const initialFilters = useRef<any[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [isCreateTicketModalShown, setIsCreateTicketModalShown] = useState<boolean>(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState<Row<RepositoryActionItem>[]>([]);
  const [latestActivities, setLatestActivities] = useState<RepoActivity[]>();
  const [isMostRecentCodeScan, setIsMostRecentCodeScan] = useState<boolean>(true);
  const skipTableResetRef = useRef<boolean>();
  const [isUnlinkConfirmModalShown, setIsUnlinkConfirmModalShown] = useState<boolean>(false);
  const [ticketToUnlink, setTicketToUnlink] = useState<RepositoryActionItem | null>(null);
  const sortBy = useRef<{ orderBy: string; desc: boolean }>({ orderBy: '', desc: false });
  const selectedActionItems = useRef<RepositoryActionItem[]>();
  const isMounted = useRef<boolean>(false);
  const pollingScanStatusInterval = useRef<NodeJS.Timeout>();

  const isGettingScanStatus = useRef<boolean>(false);

  const { show: showContextMenu } = useContextMenu({
    id: REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID,
  });

  useEffect(() => {
    isMounted.current = true;
    initQueries();
    return () => {
      setSelectedCommit && setSelectedCommit(undefined);
      clearPollingScanStatusInterval();
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedBranch) {
      setSelectedCommit && setSelectedCommit(undefined);
      setSelectedActionItem(null);
      clearPollingScanStatusInterval();
    }
  }, [selectedBranch]);

  useEffect(() => {
    if ((selectedCommit && repository?.AutoScan) || isForcePollingScanStatus) {
      clearPollingScanStatusInterval();
      setUpdatedScanStatus && setUpdatedScanStatus(null);
      setJobLogs && setJobLogs(null);
      pollingScanStatus();
      setIsForcePollingScanStatus && setIsForcePollingScanStatus(false);
    }
  }, [selectedCommit, isForcePollingScanStatus]);

  useEffect(() => {
    if (activeActionItem) {
      setSelectedActionItem(getRepoActionItem(repositories!, repository!, activeActionItem));
    }
  }, [activeActionItem]);

  useEffect(() => {
    fetchActionItemsDebounced();
  }, [globalSearch, selectedBranch, selectedCommit, codeScanActionItems]);

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipTableResetRef.current = false;
  });

  useEffect(() => {
    if (repository && selectedBranch && !isMainBranch) {
      getLatestActivities();
    }
  }, [repository, selectedBranch]);

  const imageName = route?.query?.imageName;
  const imageTag = route?.query?.imageTag;
  const imageSHA = route?.query?.imageSHA;

  const pollingScanStatus = () => {
    let timeCounter = 0;
    pollingScanStatusInterval.current = setInterval(async () => {
      if (isGettingScanStatus.current) {
        return;
      }

      isGettingScanStatus.current = true;

      timeCounter = timeCounter + POLLING_SCAN_STATUS_INTERVAL_TIME;
      if (timeCounter > POLLING_SCAN_STATUS_TIMEOUT) {
        clearPollingScanStatusInterval();
        isGettingScanStatus.current = false;
        return;
      }

      const response = await getScanStatus();

      isGettingScanStatus.current = false;

      if (response?.length) {
        const jobLogs = response[0].jobLogs;

        if (setJobLogs) {
          setJobLogs(jobLogs);
        }

        const updatedScanStatus = response[0].status;
        setUpdatedScanStatus && setUpdatedScanStatus(SCAN_STATUS_MAP[updatedScanStatus]);
        if (STOP_POLLING_SCAN_STATUSES.includes(updatedScanStatus)) {
          clearPollingScanStatusInterval();
          return;
        }
      }
    }, POLLING_SCAN_STATUS_INTERVAL_TIME);
  };

  const clearPollingScanStatusInterval = () => {
    if (pollingScanStatusInterval.current) {
      clearInterval(pollingScanStatusInterval.current);
    }
  };

  const getScanStatus = async () => {
    if (!repository || !selectedBranch || !selectedCommit) return null;

    const currentURL = new URLSearchParams(window.location.search);
    try {
      const params = new URLSearchParams(
        currentURL.has('commitHash') && selectedCommit
          ? `repository=${repository.Name}&branch=${selectedBranch.value}&commitHash=${selectedCommit.CommitHash}&codeScanId=${selectedCommit.ID}`
          : `repository=${repository.Name}&branch=${selectedBranch.value}`,
      );
      return await sendRequest('GET', `${baseURL}/ci/repo-scan-jobs?${params}`, {}, null);
    } catch (e: unknown) {
      isGettingScanStatus.current = false;

      logger.logError(
        `event_get_scan_status_${repository?.Name}_${selectedBranch?.value}_${selectedCommit?.CommitHash}`,
        e,
      );
    }
  };

  const getLatestActivities = async () => {
    if (!repository || !selectedBranch) return null;
    try {
      const params = new URLSearchParams(`repository=${repository.Name}&branch=${selectedBranch.value}`);
      const response = await sendRequest('GET', `${baseURL}/ci/repositories/scan-results?${params}`, {}, null);
      if (isMounted.current) {
        setLatestActivities(response);
      }
    } catch (e) {
      if (e instanceof Error) {
        logger.logError('event_get_repo_changes', { message: e.message });
      }
    }
  };

  // Restores user selection for hidden/ordered columns
  const tableColumnsStorage: Record<string, string[]> = useMemo(
    () => (savedSelectionsKey ? JSON.parse(localStorage.getItem(savedSelectionsKey) || '{}') : {}),
    [],
  );

  const tableHiddenColumns = useMemo(() => {
    return tableColumnsStorage?.hiddenColumns || [];
  }, []);

  const data = React.useMemo(() => {
    return actionItems;
  }, [actionItems]);

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter tableInstance={tableInstance} filters={actionItemFilters.current} />
  );

  const showCreateTicketModal = (actionItem: RepositoryActionItem) => {
    selectedActionItems.current = getSelectedActionItems(actionItem);
    if (!selectedActionItems.current.length) {
      toast.error(strings.general.noActionItemsSelected);
      return;
    }
    if (hasCreatedTicket()) {
      toast.error(strings.general.ticketAssociated);
      return;
    }
    setIsCreateTicketModalShown(true);
  };

  const resolutionSetHandler = async (actionItem: RepositoryActionItem, resolution: string) => {
    selectedActionItems.current = getSelectedActionItems(actionItem);
    if (!selectedActionItems.current.length) {
      toast.error(strings.general.noActionItemsSelected);
      return;
    }
    try {
      const payload = {
        IDs: selectedActionItems.current.map((row) => row.ID),
        Resolution: resolution,
      };
      await sendRequest('PATCH', `${baseURL}/ci/action-items/resolution/bulk`, { data: payload }, null);
      if (actionItem && actionItem.repositoryName) {
        const repositoryID = repositories?.filter((repo) => repo.Name === actionItem.repositoryName)[0]?.ID;
        if (!repositoryID) return;
        const currentAI = await fetchRepositoryActionItem(
          repositoryID,
          payload.IDs[0],
          actionItem.historicalResolution,
        );
        setCurrentActionItem(actionItem.repositoryName, currentAI);
      }
    } catch (e) {
      logger.logError('error_updating_resolution_repositories', e);
      toast.error(strings.actionItemsPage.contextMenu.resolutionError);
      return;
    }
    toast.success(strings.actionItemsPage.contextMenu.resolutionSuccess);
  };

  const snoozeSetHandler = async (actionItem: RepositoryActionItem, snoozeOption: string) => {
    selectedActionItems.current = getSelectedActionItems(actionItem);
    if (!selectedActionItems.current.length) {
      toast.error(strings.general.noActionItemsSelected);
      return;
    }
    try {
      const timeZone = getCurrentTimezone();
      const snoozeUntil = timeZone
        ? dayjs()
            .add(1, snoozeOption as ManipulateType)
            .tz(timeZone)
            .format()
        : dayjs()
            .add(1, snoozeOption as ManipulateType)
            .format();
      const payload = {
        IDs: selectedActionItems.current.map((row) => row.ID),
        SnoozeUntil: snoozeUntil,
      };
      await sendRequest('PATCH', `${baseURL}/ci/action-items/snooze/bulk`, { data: payload }, null);
      if (actionItem && actionItem.repositoryName) {
        const repositoryID = repositories?.filter((repo) => repo.Name === actionItem.repositoryName)[0]?.ID;
        if (!repositoryID) return;
        const currentAI = await fetchRepositoryActionItem(
          repositoryID,
          payload.IDs[0],
          actionItem.historicalResolution,
        );
        setCurrentActionItem(actionItem.repositoryName, currentAI);
      }
    } catch (e) {
      logger.logError('error_updating_snooze_repositories', e);
      toast.error(strings.actionItemsPage.contextMenu.snoozeError);
      return;
    }
    toast.success(
      strings.actionItemsPage.contextMenu.snoozeSuccess.replace('$action', 'snoozed for 1 ' + snoozeOption),
    );
  };

  const hasCreatedTicket = () => {
    return selectedActionItems.current?.length
      ? selectedActionItems.current.some((actionItem) => actionItem.ticketCreated)
      : false;
  };

  const unlinkTicketWrapper = async (actionItem: RepositoryActionItem) => {
    if (!isOwner) {
      return;
    }
    if (actionItem) {
      await setTicketToUnlink(actionItem);
      setTicketToUnlink(actionItem);
      setIsUnlinkConfirmModalShown(true);
    }
  };

  const executeUnlinkTicket = () => {
    if (ticketToUnlink) {
      unlinkTicket(ticketToUnlink);
      fetchActionItemsDebounced().then(() => {
        setIsUnlinkConfirmModalShown(false);
      });
    }
  };

  const columns = React.useMemo<Column<RepositoryActionItem>[]>(() => {
    const tableColumns = TABLE_COLUMNS(ColumnFiltering, showCreateTicketModal, unlinkTicketWrapper, isOwner);
    return repository ? tableColumns.filter((column) => column.Header !== Labels.Repository) : tableColumns;
  }, []);

  const filterTypes = React.useMemo<FilterTypes<RepositoryActionItem>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        filters: initialFilters.current,
        columnOrder: tableColumnsStorage?.columnOrder || COLUMNS_ORDER,
        hiddenColumns: tableHiddenColumns,
      },
      manualFilters: true,
      manualPagination: true,
      autoResetHiddenColumns: false,
      manualSortBy: true,
      autoResetFilters: !skipTableResetRef.current,
      autoResetSortBy: !skipTableResetRef.current,
      autoResetPage: !skipTableResetRef.current,
      autoResetExpanded: !skipTableResetRef.current,
      autoResetGroupBy: !skipTableResetRef.current,
      autoResetSelectedRows: !skipTableResetRef.current,
      autoResetRowState: !skipTableResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          // important to not change
          id: strings.general.selection,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: Row<RepositoryActionItem> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    if (savedSelectionsKey) {
      localStorage.setItem(
        savedSelectionsKey,
        JSON.stringify({
          hiddenColumns: tableInstance.state.hiddenColumns,
          columnOrder: tableInstance.state.columnOrder,
        }),
      );
    }
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder]);

  useEffect(() => {
    if (initialFilters?.current.length > 0 && loaded) {
      tableInstance.setAllFilters(initialFilters.current);
    }
  }, [initialFilters.current, loaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newSearchParams = new URLSearchParams();
        if (repository) {
          newSearchParams.set(strings.repository.repository, repository.Name);
        }

        if (selectedBranch) {
          newSearchParams.set(strings.repository.branch, selectedBranch.value);
        }

        const filters = await sendRequest(
          'GET',
          `${baseURL}/ci/action-items-filters?${newSearchParams}`,
          { showErrorAlert: false },
          null,
        );
        actionItemFilters.current = {
          ...actionItemFilters.current,
          ...filters,
        };
      } catch (e) {
        console.error('ERROR: ', e);
        logger.logError('error_loading_action_items_repositories_page: ', e);
        toast.error('Error loading page. Please try again!');
      }
    };
    fetchData();
  }, [organization]);

  const initQueries = () => {
    const navigation = window.performance.getEntriesByType('navigation') as any;
    if ((navigation[0].type === 'reload' || navigation[0].type === 'navigate') && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('redirect', 'true');
      router()
        .replace({ path: `${route.path}?${searchParams.toString()}` })
        .catch(() => {});
    }
  };

  const hydrateColumnFilters = (searchParams: URLSearchParams) => {
    const columnFilters: any[] = [];
    for (const item of searchParams.entries()) {
      if (tableInstance.allColumns.some((col) => col.id === item[0])) {
        const filterIdx = columnFilters.findIndex((filter) => filter.id === item[0]);
        if (filterIdx >= 0) {
          columnFilters[filterIdx].value.push({
            value: item[1],
            label: item[1],
          });
        } else {
          columnFilters.push({
            id: item[0],
            value: [
              {
                value: item[1],
                label: item[1],
              },
            ],
          });
        }
      } else {
        hydrateSecondaryFilters(item);
      }
    }
    initialFilters.current = columnFilters;
  };

  const hydrateSecondaryFilters = (item: [string, string]) => {
    switch (item[0]) {
      case 'search':
        setGlobalSearch(item[1]);
        break;
      case 'pageSize':
        tableInstance.state.pageSize = Number(item[1]) || PAGE_SIZE;
        break;
      case 'page': {
        tableInstance.state.pageIndex = Number(item[1]) || 0;
        tableInstance.gotoPage(Number(item[1]) || 0);
        break;
      }
      case 'orderBy': {
        sortBy.current = {
          ...sortBy.current,
          orderBy: item[0] === 'orderBy' ? item[1] : '',
        };
        break;
      }
      case 'desc': {
        sortBy.current = {
          ...sortBy.current,
          desc: item[1] === 'true',
        };
        if (sortBy.current.orderBy) {
          tableInstance.setSortBy([
            {
              id: sortBy.current.orderBy,
              desc: sortBy.current.desc,
            },
          ]);
        }
        break;
      }
      default:
        return;
    }
  };

  // should work for
  const setCurrentActionItem = (repositoryName: string | undefined, actionItem: RepoActionItemResponse | null) => {
    if (!actionItem) return;

    if (setActiveActionItem) {
      setActiveActionItem(actionItem);
    } else {
      // multiple repositories
      const item = getRepoActionItem(repositories!, repository!, actionItem);
      if (item) {
        item.repositoryName = repositoryName;
        setSelectedActionItem(item);
      }
    }
  };

  const onClickRefreshActionItem = async (rowOriginal: RepositoryActionItem) => {
    const ai = await fetchRepositoryActionItem(rowOriginal.repositoryID, rowOriginal.ID);
    if (ai && rowOriginal.historicalResolution) {
      ai.HistoricalResolution = rowOriginal.historicalResolution;
    }
    setCurrentActionItem(rowOriginal.repositoryName, ai);
  };

  const fetchRepositoryActionItem = async (
    repositoryID: number | undefined,
    actionItemID: number,
    historicalResolutionOverride?: string,
  ): Promise<RepoActionItemResponse | null> => {
    if (!organization || !repositoryID || !actionItemID) return null;
    try {
      const currentAI = (await sendRequest(
        'GET',
        `${baseURL}/ci/repositories/${repositoryID}/action-items/${actionItemID}`,
        {},
        null,
      )) as RepoActionItemResponse;
      if (historicalResolutionOverride) {
        currentAI.HistoricalResolution = historicalResolutionOverride; // when fetching the current AI, we want to override the historical resolution
      }
      return currentAI;
    } catch (e) {
      if (e instanceof Error) {
        logger.logError('event_get_repo_changes', { message: e.message });
      }
    }
    return null;
  };

  const fetchActionItems = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipTableResetRef.current = true;

    let searchParams: URLSearchParams;

    if (!window.location.search.includes('redirect')) {
      searchParams = new URLSearchParams();
      searchParams.set('page', pageIndex.toString());
      searchParams.set('orderBy', strings.vulnerabilities.severity_lowercase);
      searchParams.set('desc', 'true');
    } else {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('redirect');
      searchParams.set('page', searchParams.get('page') ? (searchParams.get('page') as string) : pageIndex.toString());
    }

    if (!searchParams.has('pageSize')) {
      searchParams.set('pageSize', pageSize.toString());
    }

    if (!searchParams.has('repositoryName') && repository) {
      searchParams.set('repositoryName', repository.Name);
    }

    if (selectedBranch) {
      searchParams.set(strings.repository.branch, selectedBranch.value);
    }

    if (!route?.query?.from && !route?.params?.from && selectedCommit?.CommitHash && !isMostRecentCodeScan) {
      searchParams.set('commitHash', selectedCommit.CommitHash);
    } else {
      searchParams.delete('commitHash');
    }

    if (route?.params?.from) {
      searchParams.set(strings.noTranslate.from, 'repositories');
    }

    if (!loaded && isMounted.current) {
      hydrateColumnFilters(searchParams);
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    } else {
      searchParams.delete('search');
    }

    if (imageName) {
      searchParams.set('imageName', imageName);
    } else {
      searchParams.delete('imageName');
    }

    if (imageTag) {
      searchParams.set('imageTag', imageTag);
    } else {
      searchParams.delete('imageTag');
    }

    if (imageSHA) {
      searchParams.set('imageSHA', imageSHA);
    } else {
      searchParams.delete('imageSHA');
    }

    searchParams = convertFiltersToURL(filters, searchParams);

    if (sortBy[0]) {
      searchParams.set('orderBy', `${sortBy[0].id}`);
      searchParams.set('desc', `${sortBy[0].desc}`);
    } else {
      searchParams.delete('orderBy');
      searchParams.delete('desc');
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    }

    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/ci/action-items?${searchParams.toString()}`,
        { returnHeaders: true },
        null,
      );
      const data = response.data;
      if (isMounted.current) {
        setActionItems(transformActionItems(repositories!, repository!, data.ActionItems, codeScanActionItems));
        setTotalRowCount(data.Total);
        tableInstance.pageCount = data.Total;
        setLoaded(true);
        // Check if we are on the automation-logs page to avoid a redirect if the user has switched pages
        // before we get to this replace().
        const routeName = router().currentRoute?.name;
        if (routeName && [REACT_REPOSITORIES, REACT_REPOSITORY].includes(routeName)) {
          router()
            .replace({ path: `${route.path}?${searchParams.toString()}` })
            .catch(() => {});
        }
      }
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_ci_action_items: ', e);
      toast.error('Error fetching Action Items');
    }
  }, [loaded, globalSearch, selectedBranch, selectedCommit, codeScanActionItems]);

  const fetchActionItemsDebounced = useAsyncDebounce(fetchActionItems, 250);

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef(null);
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        if (typeof resolvedRef === 'object' && resolvedRef.current) {
          resolvedRef.current.indeterminate = Boolean(indeterminate);
        }
      }, [resolvedRef, indeterminate]);

      return (
        <label>
          <input
            type="checkbox"
            ref={ref}
            {...rest}
            // Need to stop propagation otherwise row event is called again
            onClick={(e) => e.stopPropagation()}
          />
          <span />
        </label>
      );
    },
  );

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageSize],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage) {
      tableInstance.gotoPage(page);
    }
  };

  const getSelectedActionItems = (actionItem: RepositoryActionItem) => {
    const selectedActionItems = selectedFlatRows?.map((row) => row.original);
    if (actionItem) {
      const isExisted = selectedActionItems.find((item: RepositoryActionItem) => item.ID === actionItem.ID);
      return isExisted ? selectedActionItems : [...selectedActionItems, actionItem];
    }
    return selectedActionItems;
  };

  const toggleContextMenu = (e: TriggerEvent, actionItem: RepositoryActionItem) => {
    showContextMenu({ event: e, props: { actionItem } });
    // Need to stop the propagation otherwise the row is selected
    window.addEventListener(
      'keydown',
      function (event) {
        event.stopPropagation();
      },
      true,
    );
  };

  const getExportURLSearchParams = () =>
    repository?.Name && selectedBranch?.value
      ? new URLSearchParams(
          `pageSize=${totalRowCount}&repositoryName=${repository.Name}&branch=${selectedBranch.value}`,
        )
      : new URLSearchParams(`pageSize=${totalRowCount}`);

  const handleExport = async (action: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const exportOptions = EXPORT_ACTION_ITEMS_OPTIONS;

    let exportPromise;
    if (action === exportOptions[0]) {
      exportPromise = exportActionItems(
        getExportURLSearchParams(),
        baseURL,
        organization.Name,
        repositories!,
        repository!,
      );
    } else if (action === exportOptions[1]) {
      searchParams.delete('page');
      searchParams.delete('pageSize');
      exportPromise = exportActionItems(searchParams, baseURL, organization.Name, repositories!, repository!);
    }

    if (exportPromise) {
      await toast.promise(exportPromise, {
        loading: 'Exporting to CSV...',
        success: <b>Export ready!</b>,
        error: <b>An issue happened when exporting the action items.</b>,
      });
    }
  };

  return (
    <>
      <Toaster />
      {repository ? (
        selectedBranch ? (
          isMainBranch ? (
            <div className="repository__header" aria-hidden="true">
              <CardDeck>
                <Card>
                  <Card.Header className="repositories__card-header">
                    <h2>{Labels.SeveritiesBreakdown}</h2>
                  </Card.Header>
                  <Card.Body className="repository__graph" padded>
                    {loaded ? (
                      <SeveritiesBreakdown
                        baseURL={baseURL}
                        filters={tableInstance.state.filters}
                        noData={<EmptyData label={CommonLabels.NoData} />}
                        repository={repository}
                        selectedBranch={selectedBranch}
                        selectedCommit={selectedCommit}
                        setFilter={tableInstance.setFilter}
                      />
                    ) : (
                      <LoadingSpinner />
                    )}
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header className="repositories__card-header">
                    <h2>{Labels.TopIssues}</h2>
                  </Card.Header>
                  <Card.Body className="repository__graph" padded>
                    {loaded ? (
                      <TopIssues
                        baseURL={baseURL}
                        filters={tableInstance.state.filters}
                        noData={<EmptyData label={CommonLabels.NoData} />}
                        repository={repository}
                        selectedBranch={selectedBranch}
                        selectedCommit={selectedCommit}
                        setFilter={tableInstance.setFilter}
                      />
                    ) : (
                      <LoadingSpinner />
                    )}
                  </Card.Body>
                </Card>
                <RepoCommits
                  organization={organization}
                  repository={repository}
                  selectedBranch={selectedBranch}
                  setSelectedCommit={setSelectedCommit}
                  setIsMostRecentCodeScan={setIsMostRecentCodeScan}
                />
              </CardDeck>
            </div>
          ) : (
            <div className="repository__changes-wrapper">
              <div className="repository__changes-wrapper-left">
                <RepoChanges
                  organization={organization}
                  repository={repository}
                  selectedBranch={selectedBranch}
                  selectedCommit={selectedCommit}
                  setActiveActionItem={setActiveActionItem}
                  topBranches={topBranches}
                  fetchRepositoryActionItem={fetchRepositoryActionItem}
                />
              </div>
              <div className="repository__changes-wrapper-right">
                <RepoCommits
                  organization={organization}
                  repository={repository}
                  selectedBranch={selectedBranch}
                  setSelectedCommit={setSelectedCommit}
                  setIsMostRecentCodeScan={setIsMostRecentCodeScan}
                />
              </div>
            </div>
          )
        ) : (
          <></>
        )
      ) : (
        <div className="repositories--charts-container" aria-hidden="true">
          <CardDeck>
            <Card>
              <Card.Header className="repositories__card-header">
                <h2>{Labels.TopRepositories}</h2>
              </Card.Header>
              <Card.Body className="repository__graph" padded>
                {loaded ? (
                  <ImprovedRepositories
                    baseURL={baseURL}
                    noData={<EmptyData label={CommonLabels.NoData} />}
                    filters={tableInstance.state.filters}
                    setFilter={tableInstance.setFilter}
                  />
                ) : (
                  <LoadingSpinner />
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="repositories__card-header">
                <h2>{Labels.TopIssues}</h2>
              </Card.Header>
              <Card.Body className="repository__graph" padded>
                {loaded ? (
                  <TopIssues
                    baseURL={baseURL}
                    noData={<EmptyData label={CommonLabels.NoData} />}
                    filters={tableInstance.state.filters}
                    setFilter={tableInstance.setFilter}
                  />
                ) : (
                  <LoadingSpinner />
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="repositories__card-header">
                <h2>{Labels.SeveritiesBreakdown}</h2>
              </Card.Header>
              <Card.Body className="repository__graph" padded>
                {loaded ? (
                  <SeveritiesBreakdown
                    baseURL={baseURL}
                    noData={<EmptyData label={CommonLabels.NoData} />}
                    filters={tableInstance.state.filters}
                    setFilter={tableInstance.setFilter}
                  />
                ) : (
                  <LoadingSpinner />
                )}
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      )}
      <div className="repo-action-items--container">
        <Card body className="repo-action-items" data-cy="repo-action-items-card">
          <TableCardHeader
            globalSearch={globalSearch}
            isHidingColsSupported
            selectedActionItem={selectedActionItem}
            setGlobalSearch={setGlobalSearch}
            tableInstance={tableInstance}
            totalRowCount={totalRowCount}
          />
          {loaded && (
            <Table
              tableInstance={tableInstance}
              pagination={
                <Pagination
                  currentPage={tableInstance.state.pageIndex}
                  pageChange={handlePaginationChange}
                  totalCount={totalPage}
                />
              }
              onFieldsChanged={fetchActionItemsDebounced}
              onItemSelected={onClickRefreshActionItem}
              onContextMenuClicked={toggleContextMenu}
              contextMenu={
                <ContextMenu
                  showCreateTicketModal={showCreateTicketModal || showViewMode}
                  resolutionSetHandler={resolutionSetHandler}
                  snoozeSetHandler={snoozeSetHandler}
                  onPullRequestCreated={repository?.InstallationID ? onPullRequestCreated : undefined}
                  handleExport={handleExport}
                />
              }
              setSelectedFlatRows={setSelectedFlatRows}
              caption={`Action items of repository ${cluster}`}
            />
          )}
          {!loaded && <LoadingSpinner />}
        </Card>
        <DescriptionPanel
          isMainBranch={isMainBranch}
          latestActivities={latestActivities}
          organization={organization}
          selectedActionItem={selectedActionItem}
          setSelectedActionItem={setSelectedActionItem}
          setActiveActionItem={setActiveActionItem}
        />
        {(isCreateTicketModalShown || showViewMode) && (
          <CreateTicketModal
            router={router}
            closeModal={() => setIsCreateTicketModalShown(false)}
            isFrom={PAGES.Repository}
            repositoryActionItemIDs={selectedActionItems?.current?.map((actionItem) => actionItem.ID)}
            route={route}
            showModal={isCreateTicketModalShown || showViewMode || false}
            showViewMode={showViewMode}
            items={selectedActionItems.current}
            afterCreateTicketCallback={fetchActionItems}
            isOwner={isOwner}
          />
        )}
      </div>
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.Cancel}
        confirmButtonText={strings.Unlink}
        isModalShown={isUnlinkConfirmModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.general.UnlinkTicketConfirmation}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.general.UnlinkTicket}
        onConfirmClicked={executeUnlinkTicket}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsUnlinkConfirmModalShown(isModalShown ? true : false);
        }}
        modalClassName="update-app-group-confirm-modal"
        dataCyConfirmButton="delete-app-group-confirm-button"
      />
    </>
  );
};

export default ActionItemsTable;
