import { FiltersMapType } from '~globalTypes';

export const Labels = {
  severityLabels: 'severityLabels',
  Unavailable: 'Unavailable',
  ImageDetailVulnerabilitiesItemsTable: 'Image Detail Page - Vulnerabilities Items Table',
  HasFixedVersion: 'hasFixedVersion',
  Fixed: 'fixed',
} as const;

export interface ImageDetailItem {
  id: number;
  code: string;
  title: string;
  description: string;
  severityLabel: string;
  package: string;
  source: string;
  installed: string;
  fixed: string;
  createdAt: string;
  hasFixedVersion: boolean;
}

export const sortingFields = [
  'codes',
  'severityLabels',
  'titles',
  'packages',
  'installed',
  'hasFixedVersions',
  'sources',
];

export const filtersMap: FiltersMapType = {
  codes: 'code',
  titles: 'title',
  severityLabels: 'severityLabel',
  packages: 'package',
  installed: 'installed',
  hasFixedVersions: 'hasFixedVersion',
  sources: 'source',
};

export interface Member {
  CreatedOn: string;
  Email: string;
  FirstName: string;
  IsOwner: boolean;
  LastName: string;
  Organization: string;
  Teams: number[];
}

export interface DescriptionTopRow {
  title: string;
  content: string | boolean | undefined;
  isHidden?: boolean;
}

export interface AffectedImage {
  ID: number;
  SHA: string;
  Name: string;
  BaseImage: string;
  Tag: string;
  ClustersCount: number;
}

export const EXPORT_FIELDS_MAP: Record<string, string> = {
  code: 'CVE ID',
  title: 'Title',
  severityLabel: 'Severity',
  package: 'Package',
  installed: 'Installed',
  fixed: 'Fixed',
};
