import React, { useState, useMemo } from 'react';
import dayjs, { ManipulateType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import Tag from '~reactComponents/Tags/Tag.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import { Card } from '@fairwindsops/ui-components';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';

import { aggregatorOptions, costsRoutes } from '../../Costs.config.react';
import { findLabel } from '../../Costs.helpers.react';

import { colors } from '../../../Capacity/components/components.config.react';
import { getCurrentTimezone, formatCosts } from '~utils/global.helpers.react';

import { CostsOverTime, LineChartData } from '../../Costs.types.react';
import { strings } from '~utils/strings';

import './CostsOverTimeChart.react.scss';

dayjs.extend(timezone);

const aggregateDataMontlhy = (allClusters: LineChartData[]): LineChartData[] => {
  const returnData: LineChartData[] = [];
  for (let index = 0; index < allClusters.length; index++) {
    const aggregatedData: { [key: string]: { date: Date; value: number | null } } = {};
    const data = allClusters[index].data;
    for (let index = 0; index < data.length; index++) {
      const point = data[index];
      const date = point.x;
      const key = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}`;
      if (!key) {
        continue;
      }

      if (!aggregatedData[key]) {
        if (point.y) {
          aggregatedData[key] = { date: point.x, value: 0 };
        } else {
          aggregatedData[key] = { date: point.x, value: null };
        }
      }

      if (point.y) {
        aggregatedData[key]!.value! += point.y;
      }
    }
    const values = Object.values(aggregatedData).map((item) => ({ x: item.date, y: item.value }));
    returnData.push({ id: allClusters[index].id, cluster: allClusters[index].cluster, data: values });
  }
  return returnData;
};

const CostsOverTimeChart = ({ data, aggregators, route, isLoading, period }: CostsOverTime) => {
  const timeZone = getCurrentTimezone();
  const [highestCost, setHighestCost] = useState<number>(0);
  const currentAggregators = useMemo(
    () => (aggregators.length > 0 ? aggregators : aggregatorOptions.concat([{ value: 'pod', label: 'pod' }])),
    [aggregators],
  );

  const isMonthly = (formattedData: LineChartData[], period: string) => {
    return (
      period === 'daily' &&
      formattedData &&
      formattedData[0] &&
      formattedData[0].data &&
      formattedData[0].data.length > 70
    );
  };

  const formattedData = useMemo(() => {
    const finalData: LineChartData[] = [];
    let highestAmount = 0;
    if (data) {
      const linesToDisplay = data.length > 15 ? data.slice(0, 15) : [...data];
      linesToDisplay.forEach((line, idx) => {
        const firstObject = line.timeseries[0];
        if (!firstObject) return;
        const lineData = {
          id: `${idx + 1}`,
          data: [] as { x: Date; y: number }[],
        };
        finalData.push(lineData);
        currentAggregators.forEach((aggregator) => {
          const key = firstObject[aggregator.value] ? aggregator.value : findLabel(aggregator.section);
          lineData[key] = firstObject[key];
        });

        line.timeseries.forEach((datum) => {
          lineData.data.push({
            x: new Date(datum.time),
            y: datum.costs.actual.total,
          });
          if (datum.costs.actual.total > highestAmount) highestAmount = datum.costs.actual.total;
        });
      });
    }
    setHighestCost(highestAmount);
    return finalData.find((line) => line.data.length > 1) ? finalData : [];
  }, [data, route.fullPath, timeZone]);

  const aggregated = useMemo(() => {
    if (isMonthly(formattedData, period)) {
      return aggregateDataMontlhy(formattedData);
    }
    return formattedData;
  }, [formattedData]);

  const tooltip = (point: any) => {
    const matchedLine = aggregated.find((datum) => point.serieId === datum.id);
    const tooltipAggregators = Object.keys(matchedLine).filter((key) =>
      (aggregators.length > 0
        ? aggregators
        : aggregatorOptions.concat([{ value: 'pod', label: strings.efficiency.Pod }])
      )
        .map((aggregator) => (aggregator.section ? `${aggregator.section}${strings.general.Labels}` : aggregator.value))
        .includes(key),
    );
    return (
      <div className="costs-over-time-chart__tooltip">
        {tooltipAggregators.length
          ? tooltipAggregators.map((aggregator) => (
              <p>
                <strong className="costs-over-time-chart__aggregator">{`${aggregator.replace(
                  'Labels',
                  ` ${strings.general.Labels}`,
                )}:`}</strong>
                <span>
                  {Array.isArray(matchedLine[aggregator])
                    ? matchedLine[aggregator].map((label) => (
                        <span className="aggregator-labels-spacing">
                          <Tag isGray={true}>{`${label.name}=${label.value}`}</Tag>
                          &nbsp;
                        </span>
                      ))
                    : matchedLine[aggregator] || ''}
                </span>
              </p>
            ))
          : ''}
        <strong>
          {timeZone
            ? dayjs(point.data.x)
                .tz(timeZone)
                .format(period === 'daily' ? 'MM/DD' : 'MM/DD: hh:mm A')
            : dayjs(point.data.x).format(period === 'daily' ? 'MM/DD' : 'MM/DD: hh:mm A')}
          :
        </strong>
        {`  ${new Intl.NumberFormat(
          'en',
          highestCost < 0.55
            ? {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
              }
            : {
                style: 'currency',
                currency: 'USD',
              },
        ).format(point.data.y)}`}
      </div>
    );
  };

  const lineChartProps = {
    data: aggregated,
    margin: { top: 5, right: 50, bottom: 55, left: 60 },
    xScale: {
      type: 'time',
      format: period === 'daily' ? '%Y-%m-%d' : '%Y-%m-%d %H',
      useUTC: false,
      precision: isMonthly(formattedData, period) ? 'month' : period === 'daily' ? 'day' : 'hour',
    },
    xFormat: period === 'daily' ? 'time:%Y-%m-%d' : 'time:%H',
    yScale: {
      type: 'linear',
      min: 0,
      stacked: false,
      reverse: false,
    },
    axisBottom: {
      format: (value: Date) => {
        const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
        return isMonthly(formattedData, period)
          ? formatter.format('MM/YYYY')
          : period === 'daily'
            ? formatter.format('M/D')
            : formatter.format('M/D hA');
      },
      tickValues: isMonthly(formattedData, period)
        ? 'every 1 month'
        : period === 'daily'
          ? 'every 1 days'
          : strings.noTranslate.every4Hours,
      tickRotation: -45,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -45,
      format: (value: number) => {
        if (Number.isInteger(value) || highestCost < 0.55 || value > highestCost) {
          return formatCosts(value);
        }
        return '';
      },
    },
    colors: colors,
    tooltip: tooltip,
  };

  return (
    <Card className={`costs-over-time-chart${!costsRoutes.includes(route?.name || '') ? '__beta-width' : ''}`}>
      <Card.Title className="costs-over-time-chart__title">{strings.efficiency.costOverTime}</Card.Title>
      <Card.Body>
        <div
          aria-label={`Line chart displaying the cost over time`}
          role="img"
          className="costs-over-time-chart__chart"
        >
          {isLoading && <LoadingSpinner containerClassNames="costs-over-time-chart__spinner" />}
          {!isLoading && aggregated.length > 0 && <LineChart {...lineChartProps} />}
          {!isLoading && (!aggregated.length || aggregated.length < 2) && (
            <div className="costs-over-time-chart__no-data">{strings.general.noDataToDisplay}</div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CostsOverTimeChart;
