import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Column, FilterProps, Renderer } from 'react-table';
import { StylesConfig } from 'react-select';
import { JsonDiffComponent } from 'json-diff-react';

import { AuditLog } from './AuditLogs.types.react';

import { getCurrentTimezone } from '~reactHelpers';

import { OptionType } from '~globalTypes';

export const PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

dayjs.extend(timezone);

export const SELECT_STYLES: StylesConfig<OptionType, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

export const TABLE_COLUMNS = (
  ColumnFiltering: Renderer<FilterProps<AuditLog>>,
  FilterDatePicker: Renderer<FilterProps<AuditLog>>,
): Column<AuditLog>[] => {
  return [
    {
      Header: 'Activity Type',
      accessor: 'activityType',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: 'Message',
      accessor: 'message',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      Header: 'Executor',
      accessor: 'executor',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      className: 'no-overflow',
      Filter: FilterDatePicker,
      Cell: ({ value }: { value: string }) => {
        const timeZone = getCurrentTimezone();
        const formattedDate = timeZone
          ? dayjs(value).tz(timeZone).format('MM/DD/YYYY [at] HH:mm')
          : dayjs(value).format('MM/DD/YYYY [at] HH:mm');
        return <span title={formattedDate}>{formattedDate}</span>;
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
  ];
};

export const TOP_ROW = (auditLog: AuditLog) => [
  { title: 'Activity Type', content: auditLog.activityType },
  { title: 'Message', content: auditLog.message },
  { title: 'Executor', content: auditLog.executor },
  { title: 'Created At', content: auditLog.createdAt },
];

export const CONTENT = (auditLog?: AuditLog | null) => [
  {
    title: 'Difference',
    content: makeDiffComponent({ a: auditLog?.before, b: auditLog?.after }),
    isJSX: true,
  },
];

function makeDiffComponent({ a, b }: { a: any; b: any }): JSX.Element {
  try {
    if (!a || !b) {
      return <p>Nothing to compare</p>;
    }
    return (
      <JsonDiffComponent
        jsonA={a}
        jsonB={b}
        styleCustomization={{
          additionLineStyle: { color: 'green' },
          deletionLineStyle: { color: 'red' },
          unchangedLineStyle: { color: 'gray' },
          frameStyle: {
            fontFamily: 'monospace',
            whiteSpace: 'pre',
            fontSize: '0.7em',
          },
        }}
      />
    );
  } catch (e: any) {
    return <p>Error: {e?.message ?? JSON.stringify(e)}</p>;
  }
}
