import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import CloseIcon from '~reactIcons/Close.icon.react';

import { ITicketable, IViewTicketAffectedItem, IViewTicketResponse } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { MediumBasicText } from '~utils/texts.react';

import './ViewTicketModal.react.scss';
import { safeClickHandler } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { ActionItem, List } from '~views/organization/actionItems/ActionItems.types.react';
import toast from 'react-hot-toast';
import { ConfirmationDialog } from '@fairwindsops/ui-components';

type ViewTicketModalProps = {
  isViewTicketModalShown?: boolean;
  onModalClosed: () => void;
  provider: string;
  viewTicketResponse: IViewTicketResponse | null;
  ticketable?: ITicketable | null;
  actionItem?: ActionItem;
  list?: List;
  baseURL: string;
  isOwner: boolean;
};

const ViewTicketModal = ({
  isViewTicketModalShown,
  provider,
  onModalClosed,
  viewTicketResponse,
  ticketable,
  actionItem,
  list,
  baseURL,
  isOwner,
}: ViewTicketModalProps) => {
  const hasAffectedActionItems = viewTicketResponse?.AffectedActionItems?.length || false;
  const ticketLink = ticketable?.TicketLink;
  const [isUnlinkConfirmModalShown, setIsUnlinkConfirmModalShown] = useState<boolean>(false);

  const hideViewTicketModal = () => {
    onModalClosed();
  };

  const openTicketLink = safeClickHandler(() => {
    if (!ticketLink) {
      return;
    }
    window.open(ticketLink, '_blank');
  });

  const executeUnlinkTicket = () => {
    if (list && list.TicketLink) {
      sendRequest('DELETE', `${baseURL}/lists/${list?.ID}/ticket`, { showSuccessAlert: true, cache: true }, null)
        .then(() => {
          if (list) {
            list.TicketLink = undefined;
            list.TicketProvider = undefined;
            list.TicketCreatedAt = undefined;
          }
          toast.success(strings.viewTicketModal.ticketUnlinked);
          hideViewTicketModal();
          setIsUnlinkConfirmModalShown(false);
        })
        .catch((error: unknown) => {
          logger.logError('Error unlinking list ticket', error);
          toast.error(strings.viewTicketModal.errorUnlinkingTicket);
        });
    } else if (actionItem) {
      sendRequest(
        'DELETE',
        `${baseURL}/action-items/${actionItem.ID}/ticket`,
        { showSuccessAlert: false, cache: true },
        null,
      ).then(() => {
        actionItem.TicketLink = undefined;
        actionItem.TicketProvider = undefined;
        actionItem.TicketCreatedAt = undefined;
        toast.success(strings.viewTicketModal.ticketUnlinked);
        hideViewTicketModal();
        setIsUnlinkConfirmModalShown(false);
      });
    }
  };

  const unlinkTicket = safeClickHandler(() => {
    if (!isOwner) {
      return;
    }
    if (!ticketLink && !list?.TicketLink) {
      return;
    }
    setIsUnlinkConfirmModalShown(true);
  });

  const buildTitlePrefix = (item: IViewTicketAffectedItem) => {
    if (item?.ResourceNamespace && item?.ResourceName) {
      return `${item.ResourceNamespace} - ${item.ResourceName}:`;
    }
    if (item?.ResourceNamespace) {
      return `${item.ResourceNamespace}:`;
    }
    if (item?.ResourceName) {
      return `${item.ResourceName}:`;
    }
    return '';
  };

  return (
    <>
      <Modal onHide={hideViewTicketModal} show={isViewTicketModalShown}>
        <Modal.Body className="custom-modal-body">
          <div className="custom-modal-title view-ticket-modal__custom-modal-title">
            <h3>{strings.viewTicketModal.createTicket}</h3>
            <div className="custom-close-icon">
              <CloseIcon onClick={hideViewTicketModal} />
            </div>
          </div>
          {hasAffectedActionItems &&
            (ticketLink ? (
              <Modal.Footer className="create-ticket-modal__modal-footer">
                <a className="btn btn-primary" href={ticketLink} onClick={openTicketLink}>
                  {strings.viewTicketModal.viewTicket}
                </a>
              </Modal.Footer>
            ) : (
              <div>
                <>
                  <p
                    className={MediumBasicText({
                      weight: 'regular',
                      topMargin: 'lg',
                    })}
                  >
                    {strings.viewTicketModal.subTitle.replace('$provider', provider)}
                  </p>
                  <div className="view-ticket-modal__action-items">
                    <ul>
                      {(viewTicketResponse?.AffectedActionItems || []).map((item: IViewTicketAffectedItem) => (
                        <li>
                          <a href={item.TicketLink} target="_blank" rel="noopener noreferrer">
                            {buildTitlePrefix(item)} {item.Title || ' '}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              </div>
            ))}
        </Modal.Body>
        {(!hasAffectedActionItems || actionItem) && ticketLink && (
          <Modal.Footer className="create-ticket-modal__modal-footer">
            <a className="btn btn-primary" href={ticketLink} onClick={openTicketLink}>
              {strings.viewTicketModal.viewTicket}
            </a>
            {isOwner && (
              <a className="btn btn-primary" href={ticketLink} onClick={unlinkTicket}>
                {strings.viewTicketModal.UnlinkTicket}
              </a>
            )}
          </Modal.Footer>
        )}
        {!hasAffectedActionItems && !ticketLink && (
          <Modal.Footer className="create-ticket-modal__modal-footer">
            <p className="text-left">{strings.viewTicketModal.noTicketCreated}</p>
          </Modal.Footer>
        )}
      </Modal>
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.Cancel}
        confirmButtonText={strings.Unlink}
        isModalShown={isUnlinkConfirmModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.general.UnlinkTicketConfirmation}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.general.UnlinkTicket}
        onConfirmClicked={executeUnlinkTicket}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsUnlinkConfirmModalShown(isModalShown ? true : false);
        }}
        modalClassName="update-app-group-confirm-modal"
        dataCyConfirmButton="delete-app-group-confirm-button"
      />
    </>
  );
};

export default ViewTicketModal;
