import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';
import { toast } from 'react-hot-toast';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import SplitButton from './SplitButton/SplitButton.react';
import ShowingResults from '~reactComponents/ShowingResults/ShowingResults.react';

import { ACTION_ITEM_RESOLUTIONS, SNOOZE_OPTIONS } from '~utils/constants';

import {
  EXPORT_ACTION_ITEMS_OPTIONS,
  EXPORT_ACTION_ITEMS_CLUSTER_OPTIONS,
  PAGE_SIZE_OPTIONS,
} from '../ActionItems.config.react';

import ToggleColumnsVisibility from './ToggleColumnsVisibility/ToggleColumnsVisibility.react';

import {
  handleAssigneeSelection,
  handleResolutionSelection,
  handleSnoozeSelection,
} from '../ActionItems.helpers.react';
import { ActionItem, AppGroup, List, Member, Team } from '../ActionItems.types.react';
import logger from '~logger';

dayjs.extend(LocalizedFormat);

type TableCardHeader = {
  selectedActionItem: ActionItem | null;
  handleExport: (action: string) => Promise<void>;
  handleSavedListSelection: (list: List | null) => Promise<void>;
  handleGlobalSearch: (event: React.BaseSyntheticEvent) => Promise<void>;
  handleOpenModal: (type: 'query' | 'manual', list?: List | undefined) => void;
  members: Member[];
  selectedSavedList: List | null;
  totalRowCount: number;
  tableInstance: TableInstance<ActionItem>;
  lists: List[];
  teams: Team[];
  setSelectedTeam: React.Dispatch<React.SetStateAction<Team | null>>;
  setSelectedSavedList: React.Dispatch<React.SetStateAction<List | null>>;
  selectedTeam: Team | null;
  appGroups: AppGroup[];
  setSelectedAppGroup: React.Dispatch<React.SetStateAction<AppGroup | null>>;
  selectedAppGroup: AppGroup | null;
  globalSearch: string | null;
  baseURL: string;
  selectedCluster: string;
};

const TableCardHeader = ({
  selectedActionItem,
  members,
  totalRowCount,
  handleExport,
  tableInstance,
  selectedSavedList,
  handleSavedListSelection,
  lists,
  handleGlobalSearch,
  handleOpenModal,
  teams,
  setSelectedTeam,
  selectedTeam,
  appGroups,
  setSelectedAppGroup,
  selectedAppGroup,
  globalSearch,
  baseURL,
  selectedCluster,
}: TableCardHeader) => {
  const {
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, filters },
  } = tableInstance;
  const filtersCount = filters?.filter((filter) => filter.value.length).length;

  const selectedRows: ActionItem[] = useMemo(
    () => selectedFlatRows.map((row) => row.original),
    [selectedFlatRows.length],
  );

  const handleResolution = async (resolution: string) => {
    try {
      await handleResolutionSelection(resolution, selectedRows, baseURL);
      toast.success('Resolution has been updated!');
    } catch (e) {
      toast.error(e.message);
      logger.logError('error_bulk_resolution_update', e);
      console.error(e);
    }
  };

  const handleAssignee = async (email: string) => {
    try {
      await handleAssigneeSelection(email, selectedRows, baseURL);
      toast.success(!email ? 'Action items have been unassigned' : 'Action items have been assigned!');
    } catch (e) {
      logger.logError('error_bulk_assignee', e);
      toast.error(e.message);
      return;
    }
  };

  const handleSnooze = async (snoozeOption: string) => {
    try {
      await handleSnoozeSelection(snoozeOption, selectedRows, baseURL);
      toast.success('Action items have been snoozed!');
    } catch (e) {
      logger.logError('error_bulk_snooze', e);
      toast.error(e.message);
      return;
    }
  };

  return (
    <div className="action--items--header">
      <Row>
        <Col className="action--items--header--section">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="action--items--header--section__search"
              defaultValue={globalSearch || ''}
              onChange={(e) => handleGlobalSearch(e)}
              data-cy="search-text-input"
            />
          </Form>
          <hr className="solid" />
          <DropdownButton
            title={selectedSavedList?.Name || 'Saved Lists'}
            size="sm"
            className="action--items--header--section__dropdown"
            data-cy="saved-lists-dropdown"
          >
            <Dropdown.Item onSelect={() => handleSavedListSelection(null)}>Saved Lists</Dropdown.Item>
            {lists.map((list: List, idx: number) => (
              <Dropdown.Item key={idx} onSelect={() => handleSavedListSelection(list)}>
                {list.Name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <DropdownButton
            title={selectedTeam?.Name || 'Team: Name'}
            size="sm"
            className="action--items--header--section__dropdown"
          >
            <Dropdown.Item onSelect={() => setSelectedTeam(null)}>All Teams</Dropdown.Item>
            {(teams || [])?.map((team, idx) => (
              <Dropdown.Item key={idx} onSelect={() => setSelectedTeam(team)}>
                {team.Name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <DropdownButton
            title={selectedAppGroup?.name || 'App Group: Name'}
            size="sm"
            className="action--items--header--section__dropdown"
          >
            <Dropdown.Item onSelect={() => setSelectedAppGroup(null)}>All App Groups</Dropdown.Item>
            {(appGroups || [])?.map((appGroup, idx) => (
              <Dropdown.Item key={idx} onSelect={() => setSelectedAppGroup(appGroup)}>
                {appGroup.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        <Col className={`action--items--header--section end ${selectedActionItem ? 'd-none' : ''}`}>
          <DropdownButton
            title="Resolve"
            size="sm"
            disabled={!selectedFlatRows.length}
            className="action--items--header--section__dropdown"
            data-cy="bulk-resolve-dropdown"
          >
            {ACTION_ITEM_RESOLUTIONS?.map((resolution: { label: string; value: string }, idx) => (
              <Dropdown.Item key={idx} onSelect={() => handleResolution(resolution.value)}>
                {resolution.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <DropdownButton
            title="Assign"
            size="sm"
            disabled={!selectedFlatRows.length}
            className="action--items--header--section__dropdown"
            data-cy="bulk-assign-dropdown"
          >
            {members?.map((member: Member, idx: number) => (
              <Dropdown.Item key={idx} onSelect={() => handleAssignee(member.Email)}>
                {member.Email}
              </Dropdown.Item>
            ))}
            <Dropdown.Item href="#/action-3" onSelect={() => handleAssignee('')}>
              Unassign
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            title="Snooze"
            size="sm"
            disabled={!selectedFlatRows.length}
            className="action--items--header--section__dropdown"
            data-cy="bulk-resolve-dropdown"
          >
            {SNOOZE_OPTIONS?.map((item, idx: number) => (
              <Dropdown.Item key={idx} onSelect={() => handleSnooze(item)}>
                1 {item}
              </Dropdown.Item>
            ))}
          </DropdownButton>

          <DropdownButton
            title="Export"
            size="sm"
            className="action--items--header--section__dropdown"
            data-cy="export-dropdown"
          >
            {(selectedCluster ? EXPORT_ACTION_ITEMS_CLUSTER_OPTIONS : EXPORT_ACTION_ITEMS_OPTIONS)?.map(
              (exportType, idx) => (
                <Dropdown.Item key={idx} onClick={() => handleExport(exportType)}>
                  {exportType}
                </Dropdown.Item>
              ),
            )}
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <Col className="action--items--header--section">
          <ShowingResults
            size={pageSize}
            index={pageIndex}
            numOfRows={totalRowCount}
            className="action--items--header--section__showing"
          />
          <DndProvider backend={HTML5Backend} debugMode={true}>
            <ToggleColumnsVisibility tableInstance={tableInstance} selectedCluster={selectedCluster} />
          </DndProvider>
        </Col>
        <Col className={`action--items--header--section end ${selectedActionItem ? 'd-none' : ''}`}>
          <span className="action--items--header--section__results">Results per page</span>
          <DropdownButton
            title={pageSize}
            size="sm"
            className="action--items--header--section__dropdown action--items--header--section__dropdown__page-size"
          >
            {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  setPageSize(pageSizeOption);
                }}
              >
                {pageSizeOption}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <Col className="action--items--header--section">
          <SplitButton
            buttonTitle={`${selectedFlatRows.length} Selected Rows`}
            toggleTitle="Add to Manual List"
            type="manual"
            createAction={handleOpenModal}
            lists={lists}
            disabled={!selectedFlatRows.length}
          />
          <SplitButton
            buttonTitle={`${filtersCount} Selected Filters`}
            toggleTitle="Add to Query List"
            type="query"
            createAction={handleOpenModal}
            lists={lists}
            disabled={!filtersCount}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TableCardHeader;
