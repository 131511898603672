import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { IRoute, IRouter, IStore, OptionType } from '~utils/global.types.react';
import { orgBreadcrumbsList } from './RightSizing.config.react';
import {
  buildQueryStringFromJSON,
  getCurrentTimezone,
  handlePageChange,
  hasKey,
  pushURL,
} from '~utils/global.helpers.react';
import { ActionMeta, SingleValue } from 'react-select';

import { sendRequest } from '~utils/request';

import './RightSizing.react.scss';
import {
  ACTION_ITEMS,
  RIGHT_SIZING,
  RIGHT_SIZING_CLUSTER,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import AggregatorTable from '../CumulativeCosts/Components/AggregatorTable/AggregatorTable.react';
import useCostsData from '../CumulativeCosts/hooks/useCostsData.react';
import { CostsOptions, qos, ResourceSummary } from '../CumulativeCosts/Costs.types.react';
import logger from '~utils/logger';
import { baseRequest, buildColumnsQuery, formatCost } from '../CumulativeCosts/Costs.helpers.react';
import dayjs, { Dayjs } from 'dayjs';
import { strings } from '~utils/strings';
import AggregatorBar from '../CumulativeCosts/Components/AggregatorBarData/AggregatorBarData.react';
import LineCardChart, { Data } from './LineCardChart.react';
import WorkloadSelector from './WorkloadSelector.react';
import { ResourcesSummaryTimeseriesResponse, WorkloadsPodCountsResponse } from './RightSizing.types.react';
import toast, { Toaster } from 'react-hot-toast';
import { Form } from 'react-bootstrap';
import { LineChartProps } from '~reactComponents/charts/LineChart/LineChart.react';
import useWorkloadsRecommendation from '../CumulativeCosts/hooks/useWorkloadsRecommendation.react';
import useResourcePerWorkload from './hooks/useResourcePerWorkload.react';
import AsyncSelect from 'react-select/async';
import ResourceUsageData from '../CumulativeCosts/Components/ResourceUsageData/ResourceUsageData.react';
import ResourceRecommendation from '../CumulativeCosts/Components/ResourceRecommendation/ResourceRecommendation.react';
import { Point } from '@nivo/line';
import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import {
  currentOptions,
  settingsOptions,
} from '../CumulativeCosts/Components/ResourceUsageChart/ResourceUsage.config.react';
import { aggregateData, formatData } from './RightSizing.helpers.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import { DateRangeLabel, getDateRangeLabel } from '~reactComponents/ReactDatepicker/Datepicker.helper.react';
import { DEFAULT_DATE_PICKER_OPTIONS } from '~reactComponents/ReactDatepicker/Datepicker.config.react';
import {
  resolveAxisBottomFormat,
  resolveAxisBottomTickValues,
  resolveXFormat,
  resolveXScaleFormat,
  resolveXScalePrecision,
} from './RightSizing.helpers.react';
import QoSGroup from '../CumulativeCosts/Settings/QoSGroup.react';

export const topContainersAggregators = [
  { value: strings.noTranslate.cluster, label: strings.general.cluster },
  { value: strings.noTranslate.namespace, label: strings.efficiency.namespace },
  { value: strings.noTranslate.kind, label: strings.efficiency.kind },
  { value: strings.noTranslate.workload, label: strings.efficiency.workload },
  { value: strings.noTranslate.container, label: strings.efficiency.container },
];

export type RightSizingProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const podCountLegendLabels: { [key: string]: string } = {
  Min: 'Min',
  Avg: 'Avg',
  Max: 'Max',
};

// round to newest 0.5
const customRound = (x: number) => {
  return Math.round(x * 2) / 2;
};

const podCountColors: { [key: string]: string } = {
  Min: '#B45F35',
  Avg: '#BA388C',
  Max: '#932D4C',
};

const RightSizing = ({ store, route, router }: RightSizingProps) => {
  const org = store().getters.organization?.Name;
  const defaultCluster = store().getters.cluster?.Name;
  const timezone = getCurrentTimezone();

  const searchParams = new URLSearchParams(window.location.search);

  const memRecSettings =
    settingsOptions.find(({ value }) => value === searchParams.get('memRecSettings')) || currentOptions;
  const cpuRecSettings =
    settingsOptions.find(({ value }) => value === searchParams.get('cpuRecSettings')) || currentOptions;

  // non-null assertions are safe because we are checking for nulls
  const startDate = searchParams.get('startDate')
    ? searchParams.get('startDate')!
    : timezone
      ? dayjs().subtract(30, 'day').startOf('day').utc().tz(timezone).toISOString()
      : dayjs().subtract(30, 'day').startOf('day').utc().toISOString();
  const endDate = searchParams.get('endDate')
    ? searchParams.get('endDate')!
    : timezone
      ? dayjs().endOf('day').utc().tz(timezone).toISOString()
      : dayjs().endOf('day').utc().toISOString();

  defaultCluster && searchParams.set('clusters', defaultCluster);

  const queryCluster = searchParams.get('clusters') || '';
  const queryContainer = searchParams.get('containers');

  const [selectedAggregators] = useState<(OptionType | CostsOptions)[]>([
    { value: 'cluster', label: 'cluster' },
    { value: 'namespace', label: 'namespace' },
    { value: 'workload', label: 'workload' },
    { value: 'container', label: 'container' },
    { value: 'kind', label: 'kind' },
  ]);

  // this flag prevent re-selecting the same workload - it resets when the total changes to more than 1
  // image you selected cluster and namespace -- if there is a single workload, it will auto select it
  // then if you clear workload it would keep auto selecting it again and again -- this prevents that
  const [alreadyAutoSelected, setAlreadyAutoSelected] = useState(false);

  const dateOption = getDateRangeLabel(dayjs(), dayjs(startDate), dayjs(endDate));

  const [hideTable, setHideTable] = useState(true);
  const [isActionItemsLinkShown, setIsActionItemsLinkShown] = useState<boolean>(false);

  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isSavingsAvailableLoading, setIsSavingsAvailableLoading] = useState(true);

  const [workloadFound, setWorkloadFound] = useState<{
    cluster: string;
    namespace: string;
    kind: string;
    workload: string;
  } | null>();
  const [resourcesSummaryTimeseries, setResourcesSummaryTimeseries] =
    useState<ResourcesSummaryTimeseriesResponse | null>();
  const [isResourcesCPULoading, setIsResourcesCPULoading] = useState(true);
  const [isResourcesMemoryLoading, setIsResourcesMemoryLoading] = useState(true);

  const [resourcesPodTimeseries, setResourcesPodTimeseries] = useState<ResourceSummary[]>([]);

  const [workloadPodCounts, setWorkloadPodCounts] = useState<WorkloadsPodCountsResponse>();
  const [isResourcesPodCountLoading, setIsResourcesPodCountLoading] = useState(true);

  const [refreshKey, setRefreshKey] = useState(0);

  const workloadParamsExists = ['clusters', 'namespaces', 'kinds', 'workloads'].every((key) => searchParams.has(key));
  const [highestMemoryUsage, setHighestMemoryUsage] = useState<number>(0);
  const [highestCPUUsage, setHighestCPUUsage] = useState<number>(0);

  const { limits, fetchWorkloadsRecommendation } = useWorkloadsRecommendation(org);

  const [cpuHiddenIds, setCPUHiddenIds] = useState<string[]>([]);
  const [memoryHiddenIds, setMemoryHiddenIds] = useState<string[]>([]);
  const [podCountsHiddenIds, setPodCountsHiddenIds] = useState<string[]>([]);

  const [qosValue, setQoSValue] = useState<qos | null>();

  const handleSelectChange = async (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (!actionMeta.name) {
      return;
    }

    if (newValue) {
      const params = new URLSearchParams({
        [actionMeta.name]: newValue.value,
      });

      if (searchParams.get(actionMeta.name) === newValue.value) {
        return; // no change
      }

      params.forEach((value, key) => {
        searchParams.set(key, value);
      });
    } else {
      // remove the key from the search params
      searchParams.delete(actionMeta.name);
    }

    if (actionMeta.name !== 'containers') {
      searchParams.delete('containers');
      setIsSummaryLoading(true);
      setIsSavingsAvailableLoading(true);
      setIsResourcesCPULoading(true);
      setIsResourcesMemoryLoading(true);
      setResourcesSummaryTimeseries(null);
      setWorkloadFound(null);
      setQoSValue(null);
    }

    setIsResourcesPodCountLoading(true);
    setResourcesPodTimeseries([]);

    setRefreshKey((cur) => cur + 1);

    pushURL({
      route,
      nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  };

  const fetchFilterListOfValues = async (inputValue: string, type: string) => {
    if (!searchParams.get('startDate') || !searchParams.get('endDate')) {
      return;
    }

    const params = new URLSearchParams(searchParams);
    params.delete(type);

    let data;
    try {
      data = await sendRequest(
        'GET',
        `/v0/organizations/${org}/resources-filters?${params.toString()}`,
        { showSuccessAlert: false, cache: false },
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_costs_filters', e);
      return;
    }

    if (!data || !data[type]) {
      return;
    }
    return data[type]
      .filter((data: string) => {
        if (!inputValue) {
          return true;
        }
        return data.toLowerCase().includes(inputValue.toLowerCase());
      })
      .map((data: string) => ({
        label: data,
        value: data,
      }));
  };

  const {
    paramsWithAggregators,
    pageSize,
    orderBy,
    currentPage,
    setTableState,
    summaries,
    totals,
    setSelectedTimeRange,
    period,
    selectFilters,
    setResources,
    savingsAvailable,
    setSavingsAvailable,
  } = useCostsData(queryCluster, route);

  searchParams.set('pageSize', pageSize.toString());
  searchParams.set('orderBy', orderBy.orderBy);
  searchParams.set('orderByDesc', orderBy.orderByDesc);
  searchParams.set('currentPage', currentPage.toString());

  const finalTotals = useMemo(() => {
    const retrievedSavingsAvailable = savingsAvailable?.savingsAvailable || 0;
    const totalCost = totals?.totalCost || 0;

    return {
      avgBilled: totalCost / (totals?.numberOfRows || 1),
      totalBilled: totalCost,
      totalRecCosts: `${formatCost(retrievedSavingsAvailable)} (${
        retrievedSavingsAvailable !== 0 && totalCost !== 0
          ? Math.round((retrievedSavingsAvailable / totalCost) * 100)
          : 0
      }%)`,
      totalWithSavings: totalCost + retrievedSavingsAvailable,
    };
  }, [totals, savingsAvailable]);

  const fetchSummaryData = async () => {
    const dates = {
      start: searchParams.get('startDate') || startDate,
      end: searchParams.get('endDate') || endDate,
    };

    const _searchParams = paramsWithAggregators(topContainersAggregators, {}, dates);
    const searchParamsWithColumns = buildColumnsQuery(new URLSearchParams(_searchParams));

    // merge searchParams into searchParamsWithColumns
    for (const [key, value] of searchParams) {
      searchParamsWithColumns.set(key, value);
    }

    let response;
    try {
      response = await baseRequest('resources-summary', org, searchParamsWithColumns);
    } catch (e) {
      logger.logError('error_retrieving_costs_summary', e);
    }

    setResources({
      summaries: response.resources || [],
      totals: response.totals || {
        totalCost: 0,
        costRecommendationTotal: 0,
        numberOfRows: 0,
      },
    });
    setIsSummaryLoading(false);
  };

  async function fetchCostsData() {
    searchParams.set('savingsAvailableOnly', 'true');
    const savings = await baseRequest('resources-total-costs', org, searchParams);
    setSavingsAvailable(savings);
    setIsSavingsAvailableLoading(false);
  }

  async function fetchResourcesSummaryTimeseries() {
    if (!workloadParamsExists) {
      return;
    }

    const params = new URLSearchParams(searchParams);

    params.set('page', '0');
    params.set('pageSize', '99'); // fetch virtually all data for the time range
    params.append('aggregators', 'cluster');
    params.append('aggregators', 'namespace');
    params.append('aggregators', 'kind');
    params.append('aggregators', 'workload');
    params.set('addNetworkAndStorage', 'true');

    if (queryContainer) {
      params.append('aggregators', 'container');
      params.set('containers', queryContainer);
    }

    try {
      const root = (await baseRequest(
        'resources-summary-timeseries',
        org,
        params,
      )) as ResourcesSummaryTimeseriesResponse;
      const workload = root.resources[0]?.timeseries[0];

      if (!workload) {
        setWorkloadFound(null);
        return;
      }

      setWorkloadFound({
        cluster: workload.cluster,
        namespace: workload.namespace,
        kind: workload.kind,
        workload: workload.workload,
      });
      setResourcesSummaryTimeseries(root);
    } catch (e) {
      logger.logError('error_resources_summary_timeseries', e);
    }
  }

  async function fetchResourcePodTimeseries() {
    if (!workloadFound) {
      return;
    }
    const params = new URLSearchParams(searchParams);
    try {
      const root = (await baseRequest('resources-pod-timeseries', org, params)) as ResourceSummary[];
      setResourcesPodTimeseries(root);
    } catch (e) {
      logger.logError('error_resources_pod_timeseries', e);
    }
  }

  useEffect(() => {
    setIsSummaryLoading(true);
    setIsSavingsAvailableLoading(true);
    pushURL({
      route,
      nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  }, [orderBy.orderBy, orderBy.orderByDesc, currentPage, pageSize]);

  const handleClearAll = () => {
    ['startDate', 'endDate', 'clusters', 'namespaces', 'kinds', 'workloads', 'containers'].forEach((filter) =>
      searchParams.delete(filter),
    );

    setIsSummaryLoading(true);
    setIsSavingsAvailableLoading(true);
    setIsResourcesPodCountLoading(true);
    setIsResourcesCPULoading(true);
    setIsResourcesMemoryLoading(true);

    setWorkloadFound(null);
    setResourcesSummaryTimeseries(null);
    setQoSValue(null);
    setResourcesPodTimeseries([]);

    pushURL({
      route,
      nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  };

  const fetchWorkloadPodCounts = async () => {
    if (!workloadFound) {
      return;
    }
    const params = new URLSearchParams();
    params.set('startDate', searchParams.get('startDate') || startDate);
    params.set('endDate', searchParams.get('endDate') || endDate);
    params.set('namespace', workloadFound.namespace);
    params.set('kind', workloadFound.kind);
    params.set('workload', workloadFound.workload);
    // period should be calculated on the backend based workload existing data

    let response: WorkloadsPodCountsResponse | undefined = undefined;
    try {
      response = await sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${workloadFound.cluster}/workloads/pod-counts?${params}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_fetching_workloads_pod_counts', e);
      toast.error(strings.efficiency.unableProcessChanges);
    }
    setWorkloadPodCounts(response);
  };

  const fetchWorkloadQoS = async () => {
    if (!workloadFound) {
      return;
    }

    const params = new URLSearchParams();
    params.set('namespace', workloadFound.namespace);
    params.set('kind', workloadFound.kind);
    params.set('workload', workloadFound.workload);

    try {
      const { Qos } = await sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${workloadFound.cluster}/workloads?${params}`,
        {},
        null,
      );
      setQoSValue(Qos);
    } catch (e) {
      logger.logError('error_fetching_workloads_pod_counts', e);
      toast.error(strings.efficiency.unableProcessChanges);
    }
  };

  useEffect(() => {
    const fetchSummaries = async () => {
      await fetchResourcesSummaryTimeseries();
    };
    fetchSummaries();
  }, [window.location.search, qosValue]);

  useEffect(() => {
    const fetchSummaries = async () => {
      await Promise.all([fetchSummaryData(), fetchCostsData(), fetchWorkloadPodCounts(), fetchWorkloadQoS()]);
    };
    fetchSummaries();
  }, [window.location.search, workloadFound, qosValue]);

  useEffect(() => {
    if (!workloadFound) {
      return;
    }

    const fetchRecommendations = async () => {
      await fetchWorkloadsRecommendation(
        workloadFound.cluster,
        workloadFound.kind,
        workloadFound.namespace,
        workloadFound.workload,
        queryContainer,
      );
    };

    fetchRecommendations();
  }, [resourcesSummaryTimeseries]);

  useEffect(() => {
    if (!workloadFound) {
      return;
    }
    const asyncFetchResourcePodTimeseries = async () => {
      await fetchResourcePodTimeseries();
    };
    asyncFetchResourcePodTimeseries();
  }, [window.location.search, qosValue, resourcesSummaryTimeseries]);

  useEffect(() => {
    if (!workloadFound) {
      return;
    }

    const resolveActionItemsLinkShown = async () => {
      if (!workloadFound) {
        return;
      }

      const response = await sendRequest(
        'GET',
        `/v0/organizations/${org}/action-items?${buildQueryStringFromJSON({
          from: 'costs',
          ...{
            Cluster: workloadFound.cluster,
          },
          ...{
            ResourceName: workloadFound.workload,
          },
          ...{
            ResourceNamespace: workloadFound.namespace,
          },
          ...(workloadFound.kind && {
            ResourceKind: workloadFound.kind,
          }),
          ...(queryContainer && {
            ResourceContainer: queryContainer,
          }),
          Fixed: false,
          Resolution: 'None',
          ReportType: 'prometheus-metrics',
          pageSize: 0,
          page: 0,
        }).toString()}`,
        { returnHeaders: true },
        null,
      );
      setIsActionItemsLinkShown(response.headers['total-size'] && +response.headers['total-size'] >= 1);
    };

    resolveActionItemsLinkShown();
  }, [window.location.search, workloadFound]);

  const handleOnSubmit = async (qos: qos) => {
    if (!workloadFound) {
      return;
    }

    const dataToSend = {
      qos: qos,
      workload: workloadFound.workload,
      kind: workloadFound.kind,
      namespace: workloadFound.namespace,
      container: queryContainer,
    };

    try {
      await sendRequest(
        'PATCH',
        `/v0/organizations/${org}/clusters/${workloadFound.cluster}/workloads`,
        { data: dataToSend },
        null,
      );
      setQoSValue(qos);
      toast.success(strings.efficiency.settingsChanged);
    } catch (e) {
      setQoSValue(null);
      logger.logError('error_changing_costs_settings', e);
      toast.error(strings.efficiency.unableProcessChanges);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const handleOnChangeAsync = async () => {
      const newQoS = e.target.value as qos;
      await handleOnSubmit(newQoS);
    };
    handleOnChangeAsync();
  };

  const formattedPodCountChartData: Data[] = useMemo(() => {
    if (!workloadPodCounts) {
      return [];
    }
    if (!workloadFound) {
      return [];
    }
    // const timeseries = getMockWorkloadData(period);
    const podsCounts = workloadPodCounts.podCounts || [];

    const minDataSeries = podsCounts.map((podCount) => {
      return {
        x: new Date(podCount.date),
        y: podCount.min || null,
      };
    });

    const avgDataSeries = podsCounts.map((podCount) => {
      return {
        x: new Date(podCount.date),
        y: podCount.avg ? customRound(podCount.avg) : null,
      };
    });

    const maxDataSeries = podsCounts.map((podCount) => {
      return {
        x: new Date(podCount.date),
        y: podCount.max || null,
      };
    });

    setIsResourcesPodCountLoading(false);

    const filteredData = [
      {
        id: 'Min',
        data: aggregateData(minDataSeries, period),
      },
      {
        id: 'Avg',
        data: aggregateData(avgDataSeries, period),
      },
      {
        id: 'Max',
        data: aggregateData(maxDataSeries, period),
      },
    ].filter((item) => !podCountsHiddenIds.includes(item.id));

    return filteredData;
  }, [workloadPodCounts, workloadFound, podCountsHiddenIds]);

  const podCountChartProps: LineChartProps = useMemo(() => {
    return {
      data: formattedPodCountChartData,
      role: 'figure',
      curve: 'monotoneX',
      margin: { top: 25, right: 25, bottom: 50, left: 50 },
      xScale: {
        type: 'time',
        format: resolveXScaleFormat(period),
        precision: resolveXScalePrecision(period),
        useUTC: false,
      },
      xFormat: resolveXFormat(period),
      yScale: { type: 'linear', min: 'auto', max: 'auto' },
      yFormat: '>-.1f',
      axisBottom: {
        format: (value: Date) => resolveAxisBottomFormat(value, timezone, period),
        tickValues: resolveAxisBottomTickValues(period),
        legendOffset: 36,
        legendPosition: 'middle',
      },
      axisLeft: { format: '>-.1f' },
      colors: ({ id }: { id: string }): string => podCountColors[id],
      legends: [
        {
          anchor: 'bottom-left',
          direction: 'row',
          itemWidth: 80,
          itemHeight: 20,
          translateY: 50,
          data: Object.keys(podCountLegendLabels).map((item) => {
            return {
              color: podCountsHiddenIds.includes(item)
                ? 'rgba(1, 1, 1, .1)'
                : hasKey(podCountColors, item)
                  ? podCountColors[item]
                  : 'rgba(1, 1, 1, .1)',
              id: item,
              label: hasKey(podCountLegendLabels, item) ? podCountLegendLabels[item] : '',
            };
          }),
          onClick: (datum: any) => {
            setPodCountsHiddenIds((state) =>
              state.includes(String(datum.id))
                ? state.filter((item) => item !== datum.id)
                : [...state, String(datum.id)],
            );
          },
        },
      ],
      tooltip: (point: Point) => {
        return (
          <>
            <div
              style={{
                padding: '4px',
                color: '#fff',
                background: point.serieColor,
                borderRadius: '3px',
              }}
            >
              <strong>{point.serieId}</strong>: {point.data.yFormatted}
            </div>
          </>
        );
      },
    };
  }, [formattedPodCountChartData, period]);

  const formattedMemoryChartData = useMemo(() => {
    if (!resourcesSummaryTimeseries) {
      return [];
    }

    if (!workloadFound) {
      return [];
    }

    // const timeseries = getMockPodData(period);
    const timeseries = resourcesPodTimeseries;

    const formatBytesToGB = (bytes?: number): number | null => (bytes ? bytes / (1024 * 1024 * 1024) : null);

    const formattedData = formatData(
      'memoryRaw',
      timeseries,
      formatBytesToGB,
      setHighestMemoryUsage,
      limits,
      memRecSettings.value,
      queryContainer,
      period,
    ).filter((item) => !memoryHiddenIds.includes(item.id));

    setIsResourcesMemoryLoading(false);

    return formattedData;
  }, [resourcesSummaryTimeseries, memoryHiddenIds, workloadFound, limits, period]);

  const formattedCPUChartData = useMemo(() => {
    if (!resourcesSummaryTimeseries) {
      return [];
    }

    if (!workloadFound) {
      return [];
    }

    // const timeseries = getMockPodData(period);
    const timeseries = resourcesPodTimeseries;

    const formatCPUToMilliCPU = (cpu?: number): number | null => (cpu ? cpu / 1_000 : null);

    const formattedData = formatData(
      'cpuRaw',
      timeseries,
      formatCPUToMilliCPU,
      setHighestCPUUsage,
      limits,
      cpuRecSettings.value,
      queryContainer,
      period,
    ).filter((item) => !cpuHiddenIds.includes(item.id));

    setIsResourcesCPULoading(false);

    return formattedData;
  }, [resourcesSummaryTimeseries, cpuHiddenIds, workloadFound, limits, period]);

  const memoryChartProps = useMemo(
    () =>
      useResourcePerWorkload(
        'memory',
        formattedMemoryChartData,
        highestMemoryUsage,
        period,
        memoryHiddenIds,
        setMemoryHiddenIds,
      ),
    [formattedMemoryChartData, highestMemoryUsage, period, memoryHiddenIds],
  );

  const cpuChartProps = useMemo(
    () => useResourcePerWorkload('cpu', formattedCPUChartData, highestCPUUsage, period, cpuHiddenIds, setCPUHiddenIds),
    [formattedCPUChartData, highestCPUUsage, period, cpuHiddenIds],
  );

  const handleDateSelection = (start?: Dayjs | Date, end?: Dayjs | Date) => {
    if (!start || !end) {
      return;
    }

    searchParams.set('startDate', dayjs(start).toISOString());
    searchParams.set('endDate', dayjs(end).toISOString());

    setSelectedTimeRange({ start: dayjs(start).toISOString(), end: dayjs(end).toISOString() });

    setRefreshKey((cur) => cur + 1);

    setIsSummaryLoading(true);
    setIsSavingsAvailableLoading(true);
    setIsResourcesPodCountLoading(true);
    setIsResourcesCPULoading(true);
    setIsResourcesMemoryLoading(true);
    setResourcesSummaryTimeseries(null);
    setQoSValue(null);
    setResourcesPodTimeseries([]);

    pushURL({
      route,
      nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  };

  useEffect(() => {
    if (searchParams.get('startDate') && searchParams.get('endDate')) {
      return;
    }
    searchParams.set('startDate', startDate);
    searchParams.set('endDate', endDate);

    pushURL({
      route,
      nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  }, []);

  useEffect(() => {
    if (totals.numberOfRows !== 1 && alreadyAutoSelected) {
      setAlreadyAutoSelected(false);
    }
  }, [totals]);

  useEffect(() => {
    if (totals.numberOfRows !== 1) {
      return;
    }

    if (workloadParamsExists) {
      return;
    }

    if (alreadyAutoSelected) {
      return;
    }

    const row = summaries[0];
    row.cluster && searchParams.set('clusters', row.cluster);
    row.namespace && searchParams.set('namespaces', row.namespace);
    row.kind && searchParams.set('kinds', row.kind);
    row.workload && searchParams.set('workloads', row.workload);
    row.container && row.container != '' && searchParams.set('containers', row.container);

    setAlreadyAutoSelected(true);

    pushURL({
      route,
      nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  }, [totals, summaries]);

  return (
    <LayoutReact className="right-sizing">
      <Breadcrumbs
        data={orgBreadcrumbsList(org, queryCluster)}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="filter-selectors">
        <Datepicker
          isClearable={false}
          onDateSelect={handleDateSelection}
          startDate={startDate}
          endDate={endDate}
          minDate={24}
          className="right-sizing"
          dateOptionSelected={dateOption}
          onDateOptionSelect={(dateOption) => {
            if (!dateOption) {
              return;
            }
            const today = timezone ? dayjs().tz(timezone) : dayjs();
            const startDate = DEFAULT_DATE_PICKER_OPTIONS[dateOption as DateRangeLabel].startDateConverter(today);
            const endDate = DEFAULT_DATE_PICKER_OPTIONS[dateOption as DateRangeLabel].endDateConverter(today);
            handleDateSelection(startDate, endDate);
          }}
        />
        <WorkloadSelector
          searchParams={searchParams}
          refreshKey={refreshKey}
          onLoadOptions={fetchFilterListOfValues}
          onSelectChange={handleSelectChange}
          onClearAll={handleClearAll}
        />
      </div>
      <span>
        These filters match <b>{totals.numberOfRows}</b> running workloads (
        <a onClick={() => setHideTable((prev) => !prev)}>{hideTable ? 'show' : 'hide'}</a>). Narrow your criteria for
        more accurate recommendations.
      </span>
      {!hideTable && (
        <div className="costs__chart-section">
          <AggregatorTable
            route={route}
            router={router}
            aggregators={selectedAggregators}
            summaries={summaries}
            pageSize={pageSize}
            pageIndex={currentPage}
            setFilters={selectFilters}
            setTableState={setTableState}
            totalResults={totals?.numberOfRows || 0}
            isLoading={isSummaryLoading}
            onItemSelected={(row) => {
              row.cluster && searchParams.set('clusters', row.cluster);
              row.namespace && searchParams.set('namespaces', row.namespace);
              row.kind && searchParams.set('kinds', row.kind);
              row.workload && searchParams.set('workloads', row.workload);
              row.container && row.container != '' && searchParams.set('containers', row.container);
              pushURL({
                route,
                nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
                searchParams: searchParams.toString(),
                router,
              });
            }}
          />
        </div>
      )}
      <div className="costs__chart-section">
        <AggregatorBar data={finalTotals} isLoading={isSavingsAvailableLoading} />
      </div>
      <div className="pods_count__chart-section">
        {workloadParamsExists &&
          (workloadFound ? (
            <>
              <LineCardChart
                cardTitle="Pod Count"
                graphLabel="Line chart displaying the pods counts over time"
                isLoading={isResourcesPodCountLoading}
                lineChartProps={podCountChartProps}
              />
              <div className="right-sizing__qos">
                <Form id="right-sizing-form" className="right-sizing__form" noValidate>
                  <h3 className="right-sizing__form-labels">{strings.efficiency.QoS}</h3>
                  <QoSGroup
                    classNamePrefix={'right-sizing'}
                    value={qosValue}
                    onChange={handleOnChange}
                    overlayPlacement="right"
                  />
                </Form>
              </div>
              <div className="right-sizing__container-selection">
                <h2 className="right-sizing__resources-per-container">{strings.efficiency.ResourcesPerContainer}</h2>
                <AsyncSelect
                  className="select-container"
                  key={`select-container`}
                  name="containers"
                  loadOptions={(i, cb) => {
                    fetchFilterListOfValues(i, 'containers')
                      .then((values) => {
                        if (values.length == 1) {
                          // if there is only one container, select it automatically
                          searchParams.set('containers', values[0].value);
                          pushURL({
                            route,
                            nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
                            searchParams: searchParams.toString(),
                            router,
                          });
                        }
                        return values;
                      })
                      .then(cb);
                  }}
                  cacheOptions
                  defaultOptions
                  placeholder="All containers"
                  isClearable
                  onChange={handleSelectChange}
                  value={queryContainer ? { label: queryContainer, value: queryContainer } : null}
                />
                {isActionItemsLinkShown && (
                  <a
                    className="right-sizing__chart-action-items-link"
                    href={
                      router().resolve({
                        name: ACTION_ITEMS,
                        params: { org },
                        query: {
                          from: 'costs',
                          ...{ Cluster: workloadFound.cluster },
                          ...{ ResourceName: workloadFound.workload },
                          ...{ ResourceNamespace: workloadFound.namespace },
                          ...{ ResourceKind: workloadFound.kind },
                          ...(queryContainer && queryContainer !== '' && { ResourceContainer: queryContainer }),
                          Fixed: false,
                          Resolution: 'None',
                          ReportType: 'prometheus-metrics',
                        },
                      }).href
                    }
                    target={strings.noTranslate.self}
                  >
                    See Action Items
                  </a>
                )}
              </div>
              <div className="cpu-memory-graphs-container">
                <LineCardChart
                  key="memory"
                  cardTitle="Memory"
                  graphLabel="Line chart displaying the memory usage over time"
                  isLoading={isResourcesMemoryLoading}
                  lineChartProps={memoryChartProps}
                  headerComponent={() => (
                    <div className="right-sizing__settings-option-dropdown">
                      <SelectDropdown
                        label={strings.ariaLabels.settingsOption}
                        options={settingsOptions}
                        defaultValue={memRecSettings}
                        onChange={(v: unknown) => {
                          searchParams.set('memRecSettings', (v as { value: string }).value);
                          pushURL({
                            route,
                            nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
                            searchParams: searchParams.toString(),
                            router,
                          });
                        }}
                      />
                    </div>
                  )}
                  footerComponent={() => (
                    <ResourceUsageData
                      summaries={summaries}
                      type={'memoryRaw'}
                      limits={limits}
                      dates={{ start: startDate, end: endDate }}
                    />
                  )}
                />
                <LineCardChart
                  key="cpu"
                  cardTitle="CPU"
                  graphLabel="Line chart displaying the CPU usage over time"
                  isLoading={isResourcesCPULoading}
                  lineChartProps={cpuChartProps}
                  headerComponent={() => (
                    <div className="right-sizing__settings-option-dropdown">
                      <SelectDropdown
                        label={strings.ariaLabels.settingsOption}
                        options={settingsOptions}
                        defaultValue={cpuRecSettings}
                        onChange={(v: unknown) => {
                          searchParams.set('cpuRecSettings', (v as { value: string }).value);
                          pushURL({
                            route,
                            nameToMatch: defaultCluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
                            searchParams: searchParams.toString(),
                            router,
                          });
                        }}
                      />
                    </div>
                  )}
                  footerComponent={() => (
                    <ResourceUsageData
                      summaries={summaries}
                      type={'cpuRaw'}
                      limits={limits}
                      dates={{ start: startDate, end: endDate }}
                    />
                  )}
                />
              </div>
              <div className="right-sizing__recommendation">
                <ResourceRecommendation limits={limits} />
              </div>
            </>
          ) : (
            <div className="right-sizing__no-data">{strings.efficiency.noDataToDisplay}</div>
          ))}
      </div>
      <Toaster />
    </LayoutReact>
  );
};

export default RightSizing;
