import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Column, TableInstance, Renderer, FilterProps } from 'react-table';
import Select, { StylesConfig } from 'react-select';
import DOMPurify from 'dompurify';
import { Button } from 'react-bootstrap';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import Tag from '~reactComponents/Tags/Tag.react';

import { ActionItem, Member, ModalInfo } from './ActionItems.types.react';
import { IRouter, OptionType } from '~globalTypes';
import {
  formatResolutionString,
  formatResourceLabels,
  formatTime,
  handleAssigneeSelection,
} from './ActionItems.helpers.react';
import { getSeverityLabel } from '~utils/helpers';
import { getCurrentTimezone, RESIZED_WIDTH_STORAGE_KEYS } from '~reactHelpers';

import { strings } from '~utils/strings';
import { REACT_REPOSITORIES } from '~reactComponents/NavigationReact/Navigation.config.react';
import TicketCell from '~views/repositories/components/ActionItemsTable/TicketCell.react';

dayjs.extend(timezone);

export const PAGE_SIZE = 25;

export const EXPORT_ACTION_ITEMS_OPTIONS = [
  'Export all Action Items to CSV',
  'Export filtered Action Items to CSV',
  'Export SOC 2 report',
];

export const EXPORT_ACTION_ITEMS_CLUSTER_OPTIONS = [
  'Export all Action Items to CSV',
  'Export filtered Action Items to CSV',
  'Export SOC 2 report',
  'Export NSA report',
];

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

export const FILTERS_DEFAULT = {
  Severity: ['critical', 'high', 'medium', 'low', 'none'],
  Category: [strings.Efficiency, strings.Reliability, strings.Security],
  FirstSeen: ['Past Day', 'Past Week', 'Past Month'],
  LastReportedAt: ['Past Day', 'Past Week', 'Past Month'],
  Resolution: [
    {
      label: 'None',
      value: 'none',
    },
    {
      label: 'Working As Intended',
      value: 'working_as_intended',
    },
    {
      label: "Won't fix",
      value: 'wont_fix',
    },
    {
      label: 'Snoozed',
      value: 'snoozed',
    },
  ],
  Fixed: ['true', 'false'],
  TicketProvider: ['true', 'false'],
};

export const EXPORT_FORMAT: Record<string, (...args: any) => unknown> = {
  Severity: (item: { Severity: number }): string | undefined => getSeverityLabel(item.Severity),
  Assignee: (item: { AssigneeEmail: string }): string => (item.AssigneeEmail ? item.AssigneeEmail : 'None'),
  Resolution: (item: { Resolution: string }): string => formatResolutionString(item.Resolution),
  FirstSeen: (item: { FirstSeen: string }): string => formatTime(item.FirstSeen),
  LastReportedAt: (item: { LastReportedAt: string }): string => formatTime(item.LastReportedAt),
  ResourceLabels: (item: { ResourceLabels: Record<string, string> }): string | null =>
    formatResourceLabels(item.ResourceLabels),
  Notes: (item: { Notes: string }): string | null => (item.Notes ? 'Yes' : 'No'),
};

export const EVENT_TYPE = 'Event Type';

export const EXPORT_FIELDS_MAP: Record<string, string> = {
  ID: 'ID',
  Title: 'Title',
  Severity: 'Severity',
  Cluster: 'Cluster',
  ReportType: 'Report',
  Fixed: 'Fixed',
  ResourceName: 'Name',
  ResourceKind: 'Kind',
  ResourceNamespace: 'Namespace',
  ResourceContainer: 'Container',
  Category: 'Category',
  FirstSeen: 'First Seen',
  LastReportedAt: 'Last Reported',
  AssigneeEmail: 'Assignee',
  Resolution: 'Resolution',
  Description: 'Description',
  ResourceLabels: 'Label',
  Notes: 'Note',
  EventType: EVENT_TYPE,
};

export const SELECT_STYLES: StylesConfig<OptionType, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

interface MemberOptions {
  readonly value: string;
  readonly label: string;
}

export const TABLE_COLUMNS = (
  router: () => IRouter,
  ColumnFiltering: Renderer<FilterProps<ActionItem>>,
  DateRangeFilterFirstSeen: Renderer<FilterProps<ActionItem>>,
  DateRangeFilterLastReported: Renderer<FilterProps<ActionItem>>,
  members: Member[],
  baseURL: string,
  setShowModal: React.Dispatch<React.SetStateAction<ModalInfo>>,
): Column<ActionItem>[] => {
  const memberEmails: MemberOptions[] = members?.map((member) => ({
    label: member.Email,
    value: member.Email,
  }));

  const resizedWith = JSON.parse(localStorage.getItem(RESIZED_WIDTH_STORAGE_KEYS.actionItemsPage) || '{}');

  return [
    {
      Header: 'ID',
      accessor: 'ID',
      Cell: (instance: TableInstance<ActionItem>): React.JSX.Element => {
        const id = instance.row.original.ID;
        return id ? <span title={id}>{id}</span> : <span title="Unavailable">Unavailable</span>;
      },
      width: 175,
      minWidth: 175,
      disableSortBy: true,
    },
    {
      Header: 'Title',
      accessor: 'Title',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      filter: 'equals',
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: resizedWith['Title'] || 350,
      minWidth: 150,
    },
    {
      Header: 'Severity',
      accessor: 'Severity',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: number }) => <PillBadge severity={value} />,
      width: 150,
      minWidth: resizedWith['Severity'] || 130,
      maxWidth: 175,
    },
    {
      Header: 'Category',
      accessor: 'Category',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value?: string }) => <PillBadge color="low" text={value} />,
      width: 170,
      minWidth: 130,
      maxWidth: 185,
    },
    {
      Header: 'Report',
      accessor: 'ReportType',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <Tag variant={value}>{value}</Tag>,
      className: 'no-overflow',
      width: 175,
      minWidth: resizedWith['ReportType'] || 130,
      maxWidth: 200,
    },
    {
      Header: 'Resolution',
      accessor: 'Resolution',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => (
        <span title={formatResolutionString(value)}>{formatResolutionString(value)}</span>
      ),
      width: 150,
      minWidth: resizedWith['Resolution'] || 130,
      maxWidth: 200,
    },
    {
      Header: 'Cluster',
      accessor: 'Cluster',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => <span>{value}</span>,
      width: resizedWith['Cluster'] || 175,
      minWidth: 130,
    },
    {
      Header: 'Kind',
      accessor: 'ResourceKind',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => (
        <div className="no-overflow-badge">
          <Tag isGray={true}>{value}</Tag>
        </div>
      ),
      width: resizedWith['ResourceKind'] || 175,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Name',
      accessor: 'ResourceName',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => (
        <div className="no-overflow-badge">
          <Tag isGray={true}>{value}</Tag>
        </div>
      ),
      width: resizedWith['ResourceName'] || 175,
      minWidth: 130,
    },
    {
      Header: 'Namespace',
      accessor: 'ResourceNamespace',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => (
        <div className="no-overflow-badge">
          <Tag isGray={true}>{value}</Tag>
        </div>
      ),
      width: resizedWith['ResourceNamespace'] || 175,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Container',
      accessor: 'ResourceContainer',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value?: string }) => (
        <div className="no-overflow-badge">
          <Tag isGray={true}>{value || ''}</Tag>
        </div>
      ),
      width: resizedWith['ResourceContainer'] || 175,
      minWidth: 130,
    },
    {
      Header: 'Fixed',
      accessor: 'Fixed',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: boolean }) => <span title={value?.toString()}>{value?.toString() || ''}</span>,
      width: resizedWith['Fixed'] || 130,
      minWidth: 130,
      maxWidth: 175,
    },
    {
      Header: EVENT_TYPE,
      accessor: 'EventType',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: resizedWith['EventType'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Assignee',
      accessor: 'AssigneeEmail',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance<ActionItem>) => {
        const { value } = instance;
        const defaultValue = { value, label: value };

        const memberStyles: StylesConfig<MemberOptions> = {
          control: (base) => ({
            ...base,
            minHeight: 30,
            height: 30,
          }),
          indicatorsContainer: (base) => ({
            ...base,
            padding: 0,
            height: 30,
            lineHeight: 15,
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: 0,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
          }),
          valueContainer: (base) => ({
            ...base,
            height: 30,
            lineHeight: '15px',
          }),
        };
        const styles = Object.assign(memberStyles, SELECT_STYLES);
        return (
          <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            <Select
              menuPortalTarget={document.body}
              defaultValue={value ? defaultValue : null}
              className="ai-create-ticket--actions"
              placeholder="None"
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
              options={memberEmails}
              styles={styles}
              onChange={(newValue: any) => {
                // Something is off with the onChange type here. It keeps wanting this to be a
                // multivalue type, even though this is a single select. Using any..
                handleAssigneeSelection(newValue?.value || '', [instance.row.original], baseURL);
              }}
            />
          </div>
        );
      },
      width: resizedWith['AssigneeEmail'] || 175,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Ticket',
      accessor: 'TicketProvider',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance<ActionItem>) => {
        return (
          <TicketCell
            ticketLink={instance.row.original.TicketLink}
            onCreateTicketClick={() => {
              setShowModal({ modal: 'ticket', show: true, meta: { actionItem: instance.row.original } });
            }}
          />
        );
      },
      width: resizedWith['TicketProvider'] || 175,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'First Seen',
      accessor: 'FirstSeen',
      Filter: DateRangeFilterFirstSeen,
      Cell: ({ value }: { value: string }) => {
        const timeZone = getCurrentTimezone();
        return (
          <span title={timeZone ? dayjs(value).tz(timeZone).fromNow() : dayjs(value).fromNow()}>
            {timeZone ? dayjs(value).tz(timeZone).fromNow() : dayjs(value).fromNow()}
          </span>
        );
      },
      width: resizedWith['FirstSeen'] || 260,
      minWidth: 260,
      maxWidth: 260,
    },
    {
      Header: 'Last Reported',
      accessor: 'LastReportedAt',
      Filter: DateRangeFilterLastReported,
      Cell: ({ value }: { value?: string }) => {
        const timeZone = getCurrentTimezone();
        return (
          <span title={timeZone ? dayjs(value).tz(timeZone).fromNow() : dayjs(value).fromNow()}>
            {timeZone ? dayjs(value).tz(timeZone).fromNow() : dayjs(value).fromNow()}
          </span>
        );
      },
      width: resizedWith['LastReportedAt'] || 260,
      minWidth: 260,
      maxWidth: 260,
    },
    {
      Header: 'Label',
      accessor: 'ResourceLabel',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance<ActionItem>) => {
        const resourceLabels = instance.row.original.ResourceLabels;
        if (!resourceLabels || !Object.keys(resourceLabels).length) {
          return <span title="Unavailable">Unavailable</span>;
        }
        const transformedResourceLabels = [];
        let count = 0;
        for (const [key, value] of Object.entries(resourceLabels)) {
          count++;
          if (count > 3) {
            break;
          }
          transformedResourceLabels.push(`${key}=${value}`);
        }
        return (
          <div className="action--items--resource-labels">
            {transformedResourceLabels.map((resourceLabel: string) => (
              <Tag isGray={true}>{resourceLabel}</Tag>
            ))}
            {count > 3 ? <span>...</span> : <></>}
          </div>
        );
      },
      width: resizedWith['ResourceLabel'] || 350,
      minWidth: 350,
      disableSortBy: true,
    },
    {
      Header: 'Note',
      accessor: 'Notes',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance<ActionItem>) => {
        const hasNote = instance.row.original.Notes ? 'Yes' : 'No';
        return <span title={hasNote}>{hasNote}</span>;
      },
      width: resizedWith['Notes'] || 130,
      minWidth: 130,
    },
    {
      Header: 'Image Name',
      accessor: 'ImageName',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: ({ value }: { value?: string }) => <span>{value}</span>,
      width: resizedWith['ImageName'] || 175,
      minWidth: 130,
    },
    {
      Header: 'Image Tag',
      accessor: 'ImageTag',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: ({ value }: { value?: string }) => <span>{value}</span>,
      width: resizedWith['ImageTag'] || 175,
      minWidth: 130,
    },
    {
      Header: 'Image SHA',
      accessor: 'ImageSHA',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: ({ value }: { value?: string }) => <span>{value}</span>,
      width: resizedWith['ImageSHA'] || 175,
      minWidth: 130,
    },
    {
      Header: 'IaC Files',
      accessor: 'IaCFiles',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: (instance: TableInstance<ActionItem>) => {
        const actionItem = instance.row.original as ActionItem;
        const len = actionItem.IaCFiles?.length || 0;
        if (len === 0) {
          return <></>;
        }
        const textValue = len > 1 ? 'Files' : 'File';

        const queryParams = {
          resourceFilename: actionItem.IaCFiles,
          resourceKind: actionItem.ResourceKind,
          resourceName: actionItem.ResourceName,
          resourceContainer: actionItem.ResourceContainer,
          reportType: actionItem.ReportType,
          eventType: actionItem.EventType,
        };

        const filteredQueryParams = Object.fromEntries(
          Object.entries(queryParams).filter(([, value]) => value !== undefined && value !== null && value !== ''),
        );

        return (
          <a
            href={router().resolve({ name: REACT_REPOSITORIES, query: filteredQueryParams }).href}
          >{`${len} ${textValue}`}</a>
        );
      },
      width: resizedWith['IaCFiles'] || 150,
      minWidth: 130,
    },
  ];
};

export const CONTEXT_MENU_ID = 'ACTION_ITEM_TABLE_CONTEXT_MENU';

export const TOP_ROW = (actionItem: ActionItem) => [
  {
    title: 'Report',
    content: <Tag variant={actionItem.ReportType} children={actionItem.ReportType} />,
  },
  { title: 'Event', content: actionItem.EventType },
  { title: 'Cluster', content: actionItem.Cluster },
  { title: 'Namespace', content: actionItem.ResourceNamespace },
  { title: 'Kind', content: actionItem.ResourceKind },
  { title: 'Name', content: actionItem.ResourceName },
  { title: 'Container', content: actionItem.ResourceContainer },
];

export const CONTENT = (actionItem: ActionItem, onNotesUpdated: (updatedNotes: string) => void) => [
  { title: 'Description', content: DOMPurify.sanitize(actionItem.Description || '') },
  { title: 'Remediation', content: DOMPurify.sanitize(actionItem.Remediation || '') },
  { title: 'Labels', content: displayLabels(actionItem), isJSX: true },
  { title: '', content: showNotes(actionItem, onNotesUpdated), isJSX: true },
];

const displayLabels = (actionItem: ActionItem) => {
  if (!actionItem.ResourceLabels || !Object.keys(actionItem.ResourceLabels)?.length) {
    return <span title="Unavailable">Unavailable</span>;
  }
  const transformedResourceLabels = [];
  for (const [key, value] of Object.entries(actionItem.ResourceLabels)) {
    transformedResourceLabels.push(`${key}=${value}`);
  }
  return (
    <>
      {transformedResourceLabels.map((resourceLabel: string) => (
        <div className="no-overflow-badge">
          <Tag isGray={true}>{resourceLabel}</Tag>
        </div>
      ))}
    </>
  );
};

const showNotes = (actionItem: ActionItem, onNotesUpdated: (updatedNotes: string) => void) => {
  return (
    <div className="action--items--notes-field-container">
      <label htmlFor="notes-field">Notes</label>
      <textarea
        id="notes-field"
        className="action--items--notes-field"
        onChange={(e) => onNotesUpdated(e.target.value)}
      >
        {actionItem.Notes}
      </textarea>
      <span>Auto-Saving</span>
    </div>
  );
};

export const HIDDEN_COLUMNS = [
  'ID',
  'FirstSeen',
  'LastReportedAt',
  'ResourceKind',
  'Resolution',
  'Ticket',
  'Fixed',
  'ReportType',
  'EventType',
  'AssigneeEmail',
  'TicketProvider',
  'ResourceLabel',
  'Notes',
  'ImageName',
  'ImageTag',
  'ImageSHA',
];

export const COLUMNS_ORDER = [
  strings.general.selection,
  'ID',
  'Title',
  'Severity',
  'Category',
  'ReportType',
  'Resolution',
  'Cluster',
  'ResourceKind',
  'ResourceName',
  'ResourceNamespace',
  'ResourceLabel',
  'ResourceContainer',
  'AssigneeEmail',
  'TicketProvider',
  'Fixed',
  'FirstSeen',
  'LastReportedAt',
  'Notes',
];

export const TRANSFORM_OPTIONS: Record<string, (option: unknown) => void> = {
  Notes: (option: unknown) => String(option),
};
