import React from 'react';
import { Dayjs } from 'dayjs';
import { Filters, TableInstance } from 'react-table';
import { TOGGLE_TOAST } from '~store/action.types';
import { Dropdown } from 'react-bootstrap';
import { EventMap, FiltersMapType, IStore, IRouter, IRoute, CommonLabels } from '~globalTypes';
import { strings } from './strings';

type DropdownProp = {
  options: string[];
};

export const escapeEvent = (process: () => void): EventMap => ({
  name: 'keydown',
  keyMap: [
    {
      key: 'Escape',
      process,
    },
  ],
});

export const convertFiltersToURL = (filters: Filters<Record<string, unknown>>, searchParams: URLSearchParams) => {
  filters.forEach((filter) => {
    // TicketProvider is used to get the data in the table, while TicketCreated is used to filter
    // the data on the backend using a boolean.
    const filterId = filter.id === 'TicketProvider' ? 'TicketCreated' : filter.id;
    if (!filter?.value?.length) {
      searchParams.delete(filterId);
      return;
    }

    filter?.value?.forEach(({ value }: { value: string }, idx: number, arr: unknown[]) => {
      const hasParam = searchParams.has(filterId);
      if (hasParam && !searchParams.getAll(filterId).includes(value) && arr.length > 1) {
        searchParams.append(filterId, value);
      } else {
        searchParams.set(filterId, value);
      }
    });
  });

  return searchParams;
};

const formatResolutionString = (resolution?: string): string => {
  switch (resolution) {
    case '':
      return 'None';
    case strings.workingAsAttendedResolution:
      return 'Working As Intended';
    case strings.willNotFixResolution:
      return "Won't Fix";
    case strings.snoozedResolution:
      return 'Snoozed';
    default:
      return 'None';
  }
};

export const mapFilters = (
  filters: FiltersMapType,
  filtersMap: FiltersMapType,
  sortingFields: string[],
  postfixFields: { key?: string; value?: string } = {},
) => {
  if (!filters) return filters;
  const mappedFilters: FiltersMapType = {};
  for (const key in filters) {
    const mappedKey = filtersMap[key] as string;
    mappedFilters[mappedKey] = [strings.repository.historicalResolution, strings.repository.resolution].includes(
      mappedKey,
    )
      ? (filters[key] as string[]).map((element: string) => formatResolutionString(element))
      : filters[key];
    if (sortingFields.includes(key)) {
      mappedFilters[mappedKey] = sortFilterData(filters[key] as string[]);
    }
    const postfixValue = (postfixFields as Record<string, string>)[mappedKey];
    if (postfixValue) {
      mappedFilters[mappedKey] = (mappedFilters[mappedKey] as string[])?.map((field) => `${field}${postfixValue}`);
    }
  }

  return mappedFilters;
};

const sortFilterData = (filterData: string[] | null) => {
  if (!filterData?.length) return [];
  return isNumericArray(filterData)
    ? filterData
        .map(Number)
        .sort((a, b) => {
          return b - a;
        })
        .map(String)
    : filterData.sort((firstCriteria, secondCriteria) =>
        firstCriteria.toString().localeCompare(secondCriteria.toString()),
      );
};

const isNumericArray = (data: string[]) => {
  if (!data?.length) return false;
  for (const item of data) {
    if (isNaN(+item)) {
      return false;
    }
  }
  return true;
};

export const formatStatus = (value: string) => {
  return value
    .split('_')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');
};

export const REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID = 'REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU';
export const COMPLIANCE_REPORT_TABLE_CONTEXT_MENU_ID = 'COMPLIANCE_REPORT_CONTEXT_MENU';
export const VULN_ITEMS_TABLE_IMAGE_DETAIL_CONTEXT_MENU_ID = 'VULN_ITEMS_TABLE_IMAGE_DETAIL_CONTEXT_MENU_ID';
export const VULN_ITEMS_TABLE_ALL_VULNERABILITIES_CONTEXT_MENU_ID =
  'VULN_ITEMS_TABLE_ALL_VULNERABILITIES_CONTEXT_MENU_ID';
export const VULN_ITEMS_TABLE_ALL_IMAGES_CONTEXT_MENU_ID = 'VULN_ITEMS_TABLE_ALL_IMAGES_CONTEXT_MENU_ID';
export const ADDONS_PAGE_CONTEXT_MENU_ID = 'ADDONS_PAGE_CONTEXT_MENU_ID';

export const PAGES = {
  Repository: 'Repository',
  VulnerabilitiesAllImages: 'Vulnerabilities All Images',
};

// allows for typechecking of string key values in objects
export function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj;
}

export const displayToast = (message: string, isError: boolean, store: () => IStore): void => {
  const success = {
    message: message,
    position: 'b-toaster-top-center',
    type: isError ? 'error' : 'success',
  };
  store().dispatch(TOGGLE_TOAST, success);
};

export const handlePageChange = (router: () => IRouter, name: string, query?: Record<string, string>) => {
  router().push({ name, query });
};

export const DropdownOptions = ({ options }: DropdownProp): JSX.Element => {
  const dropdownItems = options.map((option) => {
    return (
      <Dropdown.Item key={option} eventKey={option}>
        {option}
      </Dropdown.Item>
    );
  });

  return <>{dropdownItems}</>;
};

export const formatDatetime = (m: Dayjs): string =>
  m.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toISOString();

type SetURLProps = {
  route: IRoute;
  nameToMatch: string;
  searchParams: string;
  router: () => IRouter;
};

export const setURL = ({ route, nameToMatch, searchParams, router }: SetURLProps) => {
  if (route.name === nameToMatch) {
    router()
      .replace({ path: `${route.path}?${searchParams}` })
      .catch(() => {
        console.warn('Failed to replace path using setURL.', { route, nameToMatch, searchParams });
      });
  }
};

// Creating a new method to prevent having to change all other locations that call setURL
// When we finally migrate to React completely, vue-router won't be used anymore.
// So, we can refactor navigation at that point.
export const pushURL = ({ route, nameToMatch, searchParams, router }: SetURLProps) => {
  if (route.name === nameToMatch) {
    let path = route.path;
    if (searchParams) {
      path += `?${searchParams}`;
    }

    router()
      .push({ path })
      .catch(() => {
        console.warn('Failed to push path using pushURL.', { route, nameToMatch, searchParams });
      });
  }
};

export const getCurrentTimezone = () => {
  const selectedTimezone = localStorage.getItem(CommonLabels.selectedTimezone) || null;
  if (!selectedTimezone) {
    return null;
  }
  const parsedSelectedTimezone = JSON.parse(selectedTimezone);
  return parsedSelectedTimezone.value.split(',')[0];
};

export const formatCosts = (value: number) =>
  `${new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
  }).format(value)}`;

export const PRE_INSTALLED_REPORTS = ['nova', 'opa', 'pluto', 'polaris', 'trivy'];

export const RESIZED_WIDTH_STORAGE_KEYS = {
  actionItemsPage: 'action-items-page-resized-width',
  vulnerabilitiesAllImagesPage: 'vulnerabilities-all-images-page-resized-width',
  vulnerabilitiesAllVulnerabilitiesPage: 'vulnerabilities-all-vulnerabilities-page-resized-width',
  imageDetailPage: 'image-detail-page-resized-width',
};

export const saveResizedWidth = (storageKey: string, tableInstance: TableInstance) => {
  const savedResizedWidth = localStorage.getItem(storageKey);
  const parsedResizedWidth = savedResizedWidth ? JSON.parse(savedResizedWidth) : {};

  parsedResizedWidth[tableInstance.column.id] = tableInstance.column.totalWidth;

  localStorage.setItem(storageKey, JSON.stringify(parsedResizedWidth));
};

export const ONRAMP_FREE_TIER_KEYS = {
  source: 'onramp:free-tier:source',
  callbackUrl: 'onramp:free-tier:callbackUrl',
};

export function safeClickHandler(handler: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) {
  return function (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
      event.preventDefault();
      handler(event);
    }
  };
}

export const buildQueryStringFromJSON = (jsonObject: Record<string, any>) => {
  const params = new URLSearchParams();
  for (const key in jsonObject) {
    /* eslint-disable no-prototype-builtins */
    if (jsonObject.hasOwnProperty(key)) {
      params.append(key, jsonObject[key]);
    }
  }

  return params.toString();
};

// This function is used to get the week number of the year
export const getWeekNumber = (date: Date): number => {
  const start = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date.getTime() - start.getTime()) / (24 * 60 * 60 * 1000));
  return Math.ceil((days + start.getDay() + 1) / 7);
};
