import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export type SingleDatePickerProps = {
  onChangeHandler: (date: Date | null) => void;
  emptyValue?: string;
  date?: Date | null;
  showCloseOnSelect?: boolean;
  closeOnScroll?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  inline?: boolean;
};

function formatDateToUS(date?: Date | null) {
  if (!date) {
    return '';
  }
  return dayjs(date).format('MM/DD/YYYY');
}

function SingleDatePicker({
  emptyValue = 'Select a date',
  date,
  onChangeHandler,
  showCloseOnSelect = true,
  closeOnScroll = true,
  showMonthDropdown = true,
  showYearDropdown = true,
  inline = true,
}: SingleDatePickerProps) {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  return (
    <Dropdown className={`datepicker-dropdown-btn dropdown-btn`} style={{ padding: '0' }} show={showDropDown}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        style={{ fontSize: '0.9em', fontWeight: '400' }}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        {formatDateToUS(date) || emptyValue}
        <button
          type="button"
          className="react-datepicker__close-icon"
          aria-label="Close"
          onClick={(e) => {
            e.stopPropagation();
            onChangeHandler(null);
          }}
        ></button>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <DatePicker
          calendarClassName="datepicker-dropdown-btn dropdown-btn"
          selected={date}
          onChange={(date) => {
            onChangeHandler(date);
            setShowDropDown(false);
          }}
          dateFormat="yyyy-MM-dd"
          placeholderText="Select a date"
          className="custom-datepicker"
          shouldCloseOnSelect={showCloseOnSelect}
          closeOnScroll={closeOnScroll}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          inline={inline}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SingleDatePicker;
