import React from 'react';
import { IRoute, IRouter, IStore } from '~globalTypes';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import VulnerabilitiesItemsTable from './components/VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.react';
import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { handlePageChange } from '~utils/global.helpers.react';
import { Labels } from './ReactAllImages.types.react';
import { strings } from '~utils/strings';

import './ReactAllImages.react.scss';

type ReactAllImagesProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const ReactAllImages = ({ route, router, store }: ReactAllImagesProps) => {
  const { organization, cluster } = store().getters;
  const baseURL = `/v0/organizations/${organization.Name}`;
  const isOwner = store().getters.isOrgOwner;
  const org = organization.Name;

  const allImageRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'react-vulnerabilities',
      label: strings.navigation.Vulnerabilities,
      href: `/orgs/${org}/vulnerabilities/all-images`,
    },
    {
      id: 'react-vulnerabilities-all-images',
      label: strings.navigation.allImages,
      href: `/orgs/${org}/vulnerabilities/all-images`,
      isActive: cluster ? undefined : true,
    },
  ];

  if (cluster) {
    allImageRoutesList.push({
      id: 'last',
      label: cluster.Name,
      href: `/orgs/${org}/vulnerabilities/all-images/${cluster.Name}`,
      isActive: true,
    });
  }

  return (
    <LayoutReact>
      <Breadcrumbs
        data={allImageRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <VulnerabilitiesItemsTable
        baseURL={baseURL}
        cluster={cluster}
        organization={organization}
        route={route}
        router={router}
        savedSelectionsKey={Labels.SaveSelectionsKey}
        isOwner={isOwner}
      />
    </LayoutReact>
  );
};

export default ReactAllImages;
