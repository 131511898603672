import React from 'react';
import { Button } from 'react-bootstrap';
import { extractTicketNumber } from '~utils/helpers';

interface TicketCellProps {
  ticketLink?: string;
  onCreateTicketClick: () => void;
}

function TicketCell({ ticketLink, onCreateTicketClick }: TicketCellProps) {
  if (!ticketLink) {
    return (
      <Button
        variant="primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCreateTicketClick();
        }}
        className="ai-create-ticket--actions"
      >
        Create Ticket
      </Button>
    );
  }
  const { provider, ticketNumber } = extractTicketNumber(ticketLink) || {};
  return (
    <Button
      variant="outline-primary"
      href={ticketLink}
      target="_blank"
      className="ai-create-ticket--actions view-ticket"
    >
      {provider && ticketNumber ? `${provider} ${ticketNumber}` : 'View Ticket'}
    </Button>
  );
}

export default TicketCell;
