import React, { Children } from 'react';
import { TableInstance, Row } from 'react-table';
import Select, { components, StylesConfig, ValueContainerProps } from 'react-select';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { FiltersMapType, OptionType } from '~globalTypes';

import { COLORS } from '~utils/styling';

import './SelectColumnFilter.react.scss';

export const filtersMap: FiltersMapType = {
  activityTypes: 'activityType',
  executors: 'executor',
  maxDate: 'maxDate',
  messages: 'message',
  minDate: 'minDate',
};

export const SELECT_STYLES: StylesConfig<OptionType, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

type SelectColumnFilterProps = {
  tableInstance: TableInstance;
  filterValues: FiltersMapType;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  const allowedTypes = ['MultiValue'];
  const childrenWithoutPlaceholder = Children.map(children, (child) => {
    const typedChild = child as Record<string, any>;
    return child && allowedTypes.includes(typedChild.type.name) ? child : null;
  });

  const length = React.Children.count(childrenWithoutPlaceholder) - 1;

  if (length >= 1) {
    return (
      <components.ValueContainer {...props}>
        {children} (+{length} others)
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const SelectColumnFilter = ({ tableInstance, filterValues }: SelectColumnFilterProps) => {
  const {
    column: { filterValue, setFilter, id, Header },
  } = tableInstance;

  let options: OptionType[] = [];
  Object.entries(filtersMap).forEach(([key, value]) => {
    if (value === id) {
      if (filterValues[key] && Array.isArray(filterValues[key])) {
        options = filterValues[key].map((option: any) => ({
          label: option,
          value: option,
        }));
      }
    }
  });

  const handleChange = (selected: any) => {
    setFilter(selected);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="custom--select"
        classNamePrefix="custom--select"
        aria-label={Header}
        styles={SELECT_STYLES}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
            primary25: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
          },
        })}
        isMulti
        value={filterValue}
        options={options}
        placeholder="Filter"
        isSearchable
        isClearable
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          // eslint-disable-next-line
          // @ts-ignore
          ValueContainer,
        }}
        onChange={(selected) => handleChange(selected)}
      />
    </div>
  );
};

export default SelectColumnFilter;
