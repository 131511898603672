import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, LayoutReact, LoadingSpinner } from '@fairwindsops/ui-components';
import { IStore, IRouter, FiltersMapType } from '~globalTypes';
import { ORG_DASHBOARD, TEAM_MANAGEMENT } from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';
import { handlePageChange } from '~utils/global.helpers.react';
import Table from '~reactComponents/Table/Table.react';
import { Card } from 'react-bootstrap';
import Pagination from '~reactComponents/pagination/pagination.react';
import SelectColumnFilter from './SelectColumnFilter.react';
import logger from '~utils/logger';
import './AuditLogs.react.scss';
import toast, { Toaster } from 'react-hot-toast';
import TableCardHeader from './TableCardHeader.react';
import { AuditLog } from './AuditLogs.types.react';
import {
  Column,
  FilterTypes,
  TableInstance,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { PAGE_SIZE } from '~views/organization/actionItems/ActionItems.config.react';
import { CONTENT, TABLE_COLUMNS, TOP_ROW } from './AuditLogs.config.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import dayjs, { Dayjs } from 'dayjs';
import { sendRequest } from '~utils/request';
import DescriptionPanel from '~reactComponents/DescriptionPanel/DescriptionPanel.react';

type AuditLogsProps = {
  store: () => IStore;
  router: () => IRouter;
};

const defaultDateRange = { start: dayjs().add(-7, 'day').startOf('day'), end: dayjs().endOf('day') };

const AuditLogs = ({ store, router }: AuditLogsProps) => {
  const { organization, isOrgOwner } = store().getters;
  const baseURL = `/v0/organizations/${organization.Name}`;

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${organization.Name}/settings`,
    },
    {
      id: 'last',
      label: strings.navigation.auditLogs,
      href: ``,
      isActive: true,
    },
  ];

  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [auditLogsFilters, setAuditLogsFilters] = useState<FiltersMapType>({});
  const [selected, setSelected] = useState<AuditLog | null>(null);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isOrgOwner) {
      return;
    }
    fetchAuditLogs();
    fetchAuditLogsFilters();
  }, []);

  const filterTypes = React.useMemo<FilterTypes<AuditLog>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter tableInstance={tableInstance} filterValues={auditLogsFilters} />
  );

  const AuditLogDatePicker = (tableInstance: TableInstance) => (
    <Datepicker
      onDateSelect={(start, end) => onDateSelect(tableInstance, start, end)}
      minDate={120}
      emptyValueLabel="Filter"
      startDate={tableInstance.state.filters
        .find((filter) => filter.id === 'createdAt')
        ?.value.start.format('YYYY-MM-DD')}
      endDate={tableInstance.state.filters.find((filter) => filter.id === 'createdAt')?.value.end.format('YYYY-MM-DD')}
    />
  );

  const columns = React.useMemo<Column<AuditLog>[]>(
    () => TABLE_COLUMNS(ColumnFiltering, AuditLogDatePicker),
    [auditLogsFilters],
  );

  const tableInstance = useTable<AuditLog>(
    {
      columns,
      data: auditLogs,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        pageIndex: 0,
        sortBy: [{ id: 'createdAt', desc: true }],
        filters: [
          {
            id: 'createdAt',
            value: defaultDateRange,
          },
        ],
      },
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
  );

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageIndex],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage) {
      tableInstance.gotoPage(page);
    }
  };

  const onDateSelect = (tableInstance: TableInstance, start?: Date | Dayjs, end?: Date | Dayjs) => {
    if (!start && !end) {
      tableInstance.setFilter('createdAt', defaultDateRange);
      return;
    }
    if (start && end) {
      const changedDates = {
        start: dayjs(start).startOf('day'),
        end: dayjs(end).endOf('day'),
      };
      tableInstance.setFilter('createdAt', changedDates);
    }
  };

  const fetchAuditLogs = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    const searchParams = new URLSearchParams(window.location.search);

    pageIndex && searchParams.set('page', pageIndex.toString());
    pageSize && searchParams.set('pageSize', pageSize.toString());
    sortBy[0] && searchParams.set('orderBy', sortBy[0]?.id);
    sortBy[0] && searchParams.set('desc', sortBy[0]?.desc ? 'true' : 'false');

    filters.forEach((param) => {
      if (param.id === 'createdAt') {
        searchParams.set('startDate', param.value.start.toISOString());
        searchParams.set('endDate', param.value.end.toISOString());
        return;
      }
      param.value.forEach((item) => {
        searchParams.append(param.id, item.value);
      });
    });

    try {
      const response = await sendRequest('GET', `${baseURL}/activities?${searchParams.toString()}`, {}, null);
      setAuditLogs(response.activities);
      setTotalRowCount(response.total);
      tableInstance.pageCount = response.total;
      setLoaded(true);
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_audit_logs: ', e);
      toast.error('Error fetching audit logs');
    }
  }, [
    loaded,
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.sortBy,
    tableInstance.state.filters,
  ]);

  const formattedSelectedItem = useMemo(() => {
    if (!selected) {
      return null;
    }
    return {
      ...selected,
      metadata: TOP_ROW(selected),
      content: CONTENT(selected),
      created: selected.createdAt,
      title: selected.activityType,
      id: selected.id,
      updated: null,
      severity: null,
      category: null,
      dateReported: null,
    };
  }, [selected]);

  const fetchAuditLogsFilters = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);

    tableInstance.state.filters.forEach((param) => {
      if (param.id === 'createdAt') {
        searchParams.set('startDate', param.value.start.toISOString());
        searchParams.set('endDate', param.value.end.toISOString());
        return;
      }
    });

    try {
      const response = await sendRequest('GET', `${baseURL}/activities-filters?${searchParams.toString()}`, {}, null);
      setAuditLogsFilters(response);
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_audit_logs_filters: ', e);
      toast.error('Error fetching audit logs filters');
    }
  }, [tableInstance.state.filters]);

  return (
    <LayoutReact>
      <Toaster />
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {isOrgOwner ? (
        <div className="audit--logs--container">
          <Card body className="audit--logs" data-cy="audit--logs--card">
            <TableCardHeader totalRowCount={totalRowCount} tableInstance={tableInstance} selected={selected} />
            {loaded && (
              <Table
                tableInstance={tableInstance}
                pagination={
                  <Pagination
                    currentPage={tableInstance.state.pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={totalPage}
                  />
                }
                onFieldsChanged={() => {
                  fetchAuditLogs();
                  fetchAuditLogsFilters();
                }}
                onItemSelected={setSelected}
                caption="Audit logs table"
              />
            )}
            {!loaded && <LoadingSpinner />}
          </Card>
          {selected && (
            <DescriptionPanel setSelectedItem={setSelected} selectedItem={formattedSelectedItem} page="audit-logs" />
          )}
        </div>
      ) : (
        <>
          <span>{strings.settings.auditLogs.notOrgOwnerMessage}</span>
        </>
      )}
    </LayoutReact>
  );
};

export default AuditLogs;
